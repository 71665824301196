import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppConstants } from 'src/app/commons/app-constants';
import { EncryptionService } from 'src/app/services/encryption.service';

@Component({
  selector: 'app-post-login',
  standalone: true,
  imports: [],
  templateUrl: './post-login.component.html',
  styleUrl: './post-login.component.css'
})
export class PostLoginComponent implements OnInit {


  constructor(
    private router: Router,
    private cookieService: CookieService) { }

    ngOnInit(): void {
      const maxAttempts = 5; // Maximum number of retry attempts
      const retryDelay = 3000; // Delay between retries in milliseconds
      let attempts = 0;
    
      const key = this.cookieService.get(AppConstants.ENCRYPTION_KEY_COOKIE_NAME);
      console.log(`Straight Cookie key is ${key} attempt is ${attempts}`);
      const checkCookie = () => {
        const token = document.cookie
          .split('; ')
          .find(row => row.startsWith(AppConstants.ENCRYPTION_KEY_COOKIE_NAME))
          ?.split('=')[1];
          console.log(`document Cookie token is ${token} attempt is ${attempts}`);
    
        if (token) {
          console.log(`Cookie Token is ${token}`);
          this.router.navigate(['/dashboard']); // Redirect to the appropriate page
        } else if (attempts < maxAttempts) {
          attempts++;
          console.log(`Attempt ${attempts}: Cookie not found. Retrying in ${retryDelay}ms...`);
          setTimeout(checkCookie, retryDelay); // Retry after the delay
        } else {
          console.log('Max attempts reached. Redirecting to login...');
          this.router.navigate(['/login']); // Handle failure
        }
      };
    
      checkCookie(); // Start the retry mechanism
    }
    
}

