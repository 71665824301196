import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SHARED_MODULES } from '../../shared-imports';
import { SubscriptionManagementComponent } from '../subscription-management/subscription-management.component';
import { TransactionHistoryComponent } from '../transaction-history/transaction-history.component';
import { SubscriptionOptedComponent } from '../subscription-opted/subscription-opted.component';
import { SubscriptionPackageComponent } from '../../tally-sheet/account-setup/subscription-package/subscription-package.component';

@Component({
  selector: 'app-subscription-view-main',
  templateUrl: './subscription-view-main.component.html',
  styleUrls: ['./subscription-view-main.component.css'],
  standalone: true,
  imports: [SHARED_MODULES, SubscriptionManagementComponent, TransactionHistoryComponent, SubscriptionOptedComponent, SubscriptionPackageComponent]
})
export class SubscriptionViewMainComponent implements OnInit {
  activeTabIndex: number = 0;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.setPageTitle();
    this.fragmentNavigation();
    //this.moveToPackageSelectionTab();
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      //  this.formHeader = data['header'];
      // this.formSubHeader = data['subHeader'];
    });
  }

  selectTab(tabIndex: number) {
    console.log(`Switching to tab ${tabIndex}`);
    this.activeTabIndex = tabIndex - 1;
    this.router.navigate([], { fragment: `tab${tabIndex}` }); // Update URL fragment
  }

  moveToPackageSelectionTab() {
    console.log(`moveToPackageSelectionTab `);

    this.route.fragment.subscribe(fragment => {
      console.log(`Received fragment: ${fragment}`);
      if (fragment) {
        const match = fragment.match(/tab(\d)/);
        if (match && match[1]) {
          const tabIndex = +match[1] - 1;
          console.log(`Parsed tabIndex: ${tabIndex}`);

          if (!isNaN(tabIndex)) {
            this.activeTabIndex = tabIndex;
          }
        } else {
          console.log(`No valid tab index found in the fragment.`);
        }
      }
    });
  }

  private fragmentNavigation() {
    this.route.fragment.subscribe((fragment: string | null) => {
      console.log('URL Fragment:', fragment);
      if (fragment === 'subscription') {
        this.activeTabIndex = 0; // Tab indices start from 0, so Tab 3 is index 2
      }
      else if (fragment === 'transactions') {
        this.activeTabIndex = 1; // Tab indices start from 0, so Tab 3 is index 2
      }
      else if (fragment === 'purchase') {
        this.activeTabIndex = 2; // Tab indices start from 0, so Tab 3 is index 2
      }
      else {
        this.activeTabIndex = 0; // Tab indices start from 0, so Tab 3 is index 2
      }
    });
  }

  onTabChange(index: number) {
    this.activeTabIndex = index;
    if (this.activeTabIndex === 0) {
      this.router.navigate([], { fragment: 'subscription' });
    }
    else if (this.activeTabIndex === 1) {
      this.router.navigate([], { fragment: 'transactions' });
    }
    if (index === 2) {
      this.router.navigate([], { fragment: 'purchase' });
    }

  }
}
