import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-email-verification-success',
  standalone: true,
  imports: [SHARED_MODULES],
  templateUrl: './email-verification-success.component.html',
  styleUrl: './email-verification-success.component.css'
})
export class EmailVerificationSuccessComponent {
  loading: boolean = true;
  verificationStatus: 'success' | 'error' | null = null;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    // Get the token from the URL
    const token = this.route.snapshot.queryParamMap.get('token');
   // alert(token);
    if (token) {
      this.authService.verifyEmail(token).subscribe(
        (response) => {
          this.loading = false;
          this.verificationStatus = 'success';
        },
        (error) => {
          this.loading = false;
          this.verificationStatus = 'error';
        }
      );
    } else {
      this.loading = false;
      this.verificationStatus = 'error';
    }
  }

  resendVerificationEmail(): void {
    // Logic to resend the verification email
    console.log('Resending verification email...');
  }

}
