import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map, of, tap } from 'rxjs';
import { AppConstants } from '../commons/app-constants';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { CurrentUser } from '../interfaces/current-user';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiUrl = AppConstants.API_URL;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
  ) { }

  login(credentials: any): Observable<any> {
    return this.http.post(
      AppConstants.AUTH_API + 'signin',
      {
        email: credentials.username,
        password: credentials.password,
      },
      httpOptions
    );
  }

  register(user: any): Observable<any> {
    return this.http.post(
      AppConstants.AUTH_API + 'signup',
      {
        name: user.name,
        email: user.email,
        password: user.password,
        matchingPassword: user.matchingPassword,
        socialProvider: 'LOCAL',
      },
      httpOptions
    );
  }


  isUserLoggedIn(): Observable<boolean> {

    const isUserLoggedInUrl = `${AppConstants.API_URL}openforall/api/v1/login/is-user-loggedin`;
    // console.log(`isUserLoggedInUrl is ${isUserLoggedInUrl}`);
    this.callAppComponentInit();
    return this.http.get<boolean>(isUserLoggedInUrl);
  }

  getSecretKey(): Observable<string> {
    return this.http.get<string>(`${AppConstants.API_URL}api/v1/login/secret-key`);
  }

  getUserDetails(): Observable<string> {
    return this.http.get<string>(`${AppConstants.API_URL}api/v1/login/user-details`);
  }

  private invokeInitSource = new Subject<void>();
  invokeInit$ = this.invokeInitSource.asObservable();

  callAppComponentInit() {
    // this.invokeInitSource.next();
  }

  checkEmailExists(email: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}api/v1/login/check-email`, { params: { email } });
  }

  preRegister(user: any): Observable<any> {
    const url = `${this.apiUrl}api/v1/auth/pre-register`;
    const requestBody = { name: user.name, email: user.email, password: user.password };

    return this.http.post<any>(
      url, requestBody, httpOptions);
  }

  customLogin(user: any): Observable<any> {
    console.log(`CustomLogin ${JSON.stringify(user)}`)
    const url = `${this.apiUrl}api/v1/login`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'deviceId': user.deviceId, // Add the deviceId here
      }),
    };
    const requestBody = { email: user.email, password: user.password, deviceId: user.deviceId };

    return this.http.post<any>(
      url, requestBody, httpOptions);
  }

  // http://localhost:8080/api/v1/verify-email?token=306e4dbf-d5c3-433c-8c9c-d833fd59144f

  verifyEmail(token: string): Observable<any> {
    const url = `${this.apiUrl}api/v1/verify-email?token=${token}`
    return this.http.get<any>(url);
  }
}
