export const AppRoutePathConstants = {
    PATH_SERVER_URL:  'http://localhost:8080/',
    PATH_TALLY_SHEET: 'tally-sheet/tally-sheet',
    PATH_DASHBOARD: 'dashboard',
    PATH_HOPPUS: 'hoppus',
    PATH_LOGIN: 'login',
    PATH_LOGIN_ADD_USER: 'login/addUser',
    PATH_PAGE_NOT_FOUND: 'pagenotfound',
    PATH_BUYERS: 'buyers',
    PATH_ADD_BUYER: 'add/buyer',
    PATH_EDIT_BUYER: 'edit/buyer/:id',
    PATH_SELLERS: 'sellers',
    PATH_ADD_SELLER: 'add/seller',
    PATH_EDIT_SELLER: 'edit/seller/:id',
    PATH_LOADING_SITES: 'loading-sites',
    PATH_ADD_LOADING_SITE: 'add/loading-sites',
    PATH_EDIT_LOADING_SITE: 'edit/loading-sites/:id',
    PATH_PRODUCTS: 'products',
    PATH_ADD_PRODUCT: 'add/product',
    PATH_EDIT_PRODUCT: 'edit/product/:id',
    PATH_CONTAINERS: 'containers',
    PATH_ADD_CONTAINER: 'add/container',
    PATH_EDIT_CONTAINER: 'edit/container/:id',
    PATH_MEASUREMENTS: 'measurements',
    PATH_ADD_MEASUREMENT: 'add/measurement',
    PATH_EDIT_MEASUREMENT: 'edit/measurement',
    PATH_VIEW_MEASUREMENT: 'view/measurement/:containerUUID',
    PATH_BILL_OF_LADINGS: 'bill-of-ladings',
    PATH_ADD_BILL_OF_LADING: 'add/bill-of-lading',
    PATH_EDIT_BILL_OF_LADING: 'edit/bill-of-lading',
    PATH_VIEW_BILL_OF_LADING: 'view/bill-of-lading/:blUUID',
    PATH_ADD_USER: 'add/user',
    PATH_MANAGE_USER: 'manage/user',
    PATH_PUBLIC_VIEW_MEASUREMENT: 'public/view/measurement/:containerUUID',
    PATH_PUBLIC_VIEW_BILL_OF_LADINGS: 'public/view/bill-of-ladings/:blUUID',
    PATH_ERROR_FORBIDDEN: 'error/forbidden',
    PATH_RESTRICTED_PAGE: 'restrictedPage',
    PATH_REGISTER: 'register',
    PATH_EDIT_COMPANY: 'edit/company',
    PATH_ERROR_UNUSUAL: 'error/unusual',
    PATH_REGISTRATION: 'registration',
    PATH_SUBSCRIPTIONS: 'subscriptions',
    PATH_ERROR_NOT_APPROVED: 'error/notapproved',
    PATH_ERROR_ALREADY_LOGGED_IN: 'error/alreadyLogedIn',
    PATH_SAWNLIST: 'sawnlist',
    PATH_TIMBER: 'timber',
    PATH_VIEW_SAWN_TIMBER: 'view-sawn-timber',
    PATH_PUBLIC_VIEW_SAWN_TIMBER: 'public/view/sawn-timber/:containerUUID',
    PATH_UTILITY_UNIT_CONVERSION: 'utility/unit-conversion',
    PATH_UTILITY_VOLUME_ESTIMATES: 'utility/volume-estimates',
    PATH_UTILITY_SLAB_GENERATOR: 'utility/slab-generator',
    PATH_PAYMENT_SUCCESS: 'payment/success',
    PATH_USER_ADDED_TO_EXISTING_COMPANY_SUCCESS: 'user-added-to-existing-company/success',
    PATH_PAYMENT_FAILURE: 'payment/failure',
    PATH_ROOT_MASTER_SUBSCRIPTION: 'root/master-subscription',
    PATH_ROOT_MASTER_SUBSCRIPTION_ID: 'root/master-subscription/:id',
    PATH_CONTAINER_PRIVACY: 'container-privacy',
    PATH_ROOT_MASTER_SUBSCRIPTIONS: 'root/master-subscriptions',
    PATH_LOCATIONS: 'locations', 
    PATH_ACCOUNT_SETUP: 'account-setup', 
    PATH_ACCOUNT_DETAILS: 'account-setup/account-details', 
    PATH_COMPANY_DETAILS: 'account-setup/company-details', 
    PATH_SUBSCRIPTION: 'account-setup/subscription', 
    PATH_PAYMENT: 'account-setup/payment', 
    PATH_PAYMENT_CONFIRMATION: 'payment-confirmation', 
    PATH_PAYMENT_ERROR: 'payment-error', 
    PATH_EDIT_ACCOUNT_DETAILS: 'edit/account-details', 
    PATH_TALLY_SHEET_CHART: 'tally-sheet/chart', 
    PATH_TALLY_SHEET_REGISTER: 'tally-sheet/register', 
    PATH_TALLY_SHEET_SUBSCRIBE: 'tally-sheet/subscribe', 
    PATH_TALLY_SHEET_DASHBOARD: 'tally-sheet/dashboard', 
    PATH_TALLY_SHEET_CONFIRM_LOGOUT: 'tally-sheet/confirm-logout-devices', 
    PATH_UNAUTHORIZED: 'unauthorized', 
    PATH_TALLY_SHEETS: 'tally-sheets', 
    PATH_CONTAINER_VIEW: 'container-view/:id', 
    PATH_BL_VIEW: 'bl-view/:id', 
    PATH_POST_LOGIN: 'post-login', 
    PATH_EMAIL_VERIFICATION_PROMPT: 'email-verification-prompt', 
    PATH_EMAIL_VERIFICATION_SUCCESS:'email-verification',
     PATH_PASSWORD_RESET:'password-reset',
    // PATH_PAGE_NOT_FOUND: '**'
};
