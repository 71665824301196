import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { InplaceModule } from 'primeng/inplace';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SpeedDialModule } from 'primeng/speeddial';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { DividerModule } from 'primeng/divider';
import { FieldsetModule } from 'primeng/fieldset';
import { PasswordModule } from 'primeng/password';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import { CalendarModule } from 'primeng/calendar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownFilterOptions, DropdownModule } from 'primeng/dropdown';
import { AccountSetupStepperService } from 'src/app/services/tally-sheet/account-setup-stepper.service';
import { UserRegistrationService } from 'src/app/modules/extras/services/user-registration.service';
import { LocationSelectionComponent } from 'src/app/modules/commons/location-selection/location-selection.component';
import { ResetCommonService } from 'src/app/commons/services/reset-common.service';
import { AsYouType, parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';

import { CommonsModule } from 'src/app/modules/commons/commons.module';
import { LocationSelectionService } from 'src/app/modules/commons/location-selection/service/location-selection.service';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';
import { Message, MessageService } from 'primeng/api';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';


@Component({
  selector: 'app-account-details',
  standalone: true,
  imports: [SHARED_MODULES, LocationSelectionComponent],
  providers: [MessageService],
  templateUrl: './account-details.component.html',
  styleUrl: './account-details.component.scss'
})
export class AccountDetailsComponent implements OnInit, AfterViewInit {
  isMobileReadonly = false;
  isEmailReadonly = false;
  accountForm!: FormGroup;
  personalForm!: FormGroup;
  @Input() isSubmittedFromSidebar: boolean = false;
  existingLocationData!: any;
  @ViewChild(LocationSelectionComponent) locationSelectionComponent!: LocationSelectionComponent;
  filterValue: string | undefined = '';
  isValidPhone = false;
  mobileInfo: any;
  showNativeCalendar = false;
  changePasswordForm: FormGroup;
  displayDialog = false; // Control dialog visibility
  // locationSelectionComponent!: LocationSelectionComponent;
  genderType: any[] = [
    'Male',
    'Female',
    'Prefer not to say'

  ];
  dummy!: { name: string; code: string; }[];
  prepopulateData: any;
  passwordChangeMessages: Message[] | undefined;
  ///////
  // countries = [
  //   { name: 'India', code: 'IN', dialCode: '+91' },
  //   { name: 'United States', code: 'US', dialCode: '+1' },
  //   { name: 'United Kingdom', code: 'GB', dialCode: '+44' },
  // ];
  countries = [];
  selectedCountry = this.countries[0]; // Default to India
  phoneDetails: string[] = []; // Array to store printed phone details
  errorMessage = '';
  otp: number | null;
  /////////

  showOTPDialog = false;
  constructor(
    private fb: FormBuilder,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private stepperService: AccountSetupStepperService,
    private userRegistrationService: UserRegistrationService,
    private resetServiceCommon: ResetCommonService,
    private route: ActivatedRoute,
    private locationService: LocationSelectionService,
    private toastMessageService: ToastMessagesService

  ) {
    const isMobile = this.deviceService.isMobile();
    if (isMobile) {
      this.showNativeCalendar = true;
    }
    this.fetchCountries();
  }

  ngAfterViewInit(): void {
    // this.populateForm();
    //alert(this.locationSelectionComponent);
    if (this.existingLocationData) {
      if (this.locationSelectionComponent) {
        // alert(`2`);
        this.locationSelectionComponent.markAllControlsAsPristine();
        const locationControl = this.personalForm.get('location');
        if (locationControl) {
          locationControl.markAsPristine({ onlySelf: false });
        }
      }
    }
  }

  ngOnInit() {
    this.checkPathForEditForm();
    this.initAccountForm();
    this.initPersonalForm();
    this.initChangePasswordForm();
    this.route.data.subscribe((data) => {
      //alert(`1`);
      const accountDetails = data['accountDetails'];
      console.log(`Account Details ${JSON.stringify(accountDetails)}`)
      this.existingLocationData = accountDetails.personal.location; // Store location data if needed
      this.prepopulateData = accountDetails;
      this.patchForms(accountDetails);
      this.isMobileReadonly = true;
      this.isEmailReadonly = true;
      this.removeValidators();
      //console.log(`Location Personal Account ${JSON.stringify(accountDetails.personal.location)}`);
      this.accountForm.markAsPristine();
      this.personalForm.markAsPristine();


      // this.existingLocationData = accountDetails.personal.location; // Store location data if needed
    });
  }

  initChangePasswordForm() {
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    });
  }

  initAccountForm() {
    this.accountForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      // password: ['', [Validators.required, this.passwordValidator]],
      // confirmPassword: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.minLength(8)]],

      // isMobileVerified: [true, Validators.required],
      // isEmailVerified: ['', Validators.required],
      isMobileVerified: [null, [Validators.required]],
      isEmailVerified: [null, [Validators.required]],
      socialLogin: [null],
      isdCode: [this.selectedCountry, Validators.required],
      // phoneNumber: ['', Validators.required],
    }, { validators: this.passwordsMatchValidator });

    // Debugging: Monitor phone number changes
    this.accountForm.get('mobile')?.valueChanges.subscribe((value) => {
      console.log('Phone number input changed:', value);
      this.validateAsYouType(value);
    });


  }

  validateAsYouType(mobile: string) {
    const countryCode = this.accountForm.get('isdCode')?.value?.iso2 ?? '';

    console.log('Selected Country:', this.accountForm.get('isdCode')?.value);
    console.log('Country Code:', countryCode);

    if (mobile) {
      const formatter = new AsYouType(countryCode);
      const formattedNumber = formatter.input(mobile);

      console.log('Formatted Number:', formattedNumber);
      console.log('National Number:', formatter.getNationalNumber());
      console.log('Country Detected:', formatter.country);

      if (formatter.isValid()) {
        console.log('As-you-type validation: VALID');
        this.isValidPhone = true;
      } else {
        console.log('As-you-type validation: INVALID');
        this.isValidPhone = false;
      }
    } else {
      console.log('Phone number is empty.');
      this.isValidPhone = false;

    }
  }

  chek() {
    this.errorMessage = '';
    const formValue = this.accountForm.value;
    console.log(`formValue.country ${JSON.stringify(formValue.isdCode)}`)
    const fullMobile = `${formValue.isdCode.isdCode}${formValue.mobile}`;
    const phoneNumberParsed: PhoneNumber | undefined = parsePhoneNumberFromString(
      fullMobile,
      formValue.isdCode.iso2
    );

    console.log('Templatexxxxx xxxxx'); // Required static log
    if (phoneNumberParsed) {
      this.mobileInfo = {
        Number: phoneNumberParsed.number,
        Country: phoneNumberParsed.country,
        National: phoneNumberParsed.formatNational(),
        International: phoneNumberParsed.formatInternational(),
        URI: phoneNumberParsed.getURI(),
        // Type: phoneNumberParsed.getType(),
        Possible: phoneNumberParsed.isPossible(),
        Valid: phoneNumberParsed.isValid(),
      };

      // Log details for debugging
      for (const [key, value] of Object.entries(this.mobileInfo)) {
        console.log(`${key}: ${value}`);
        this.phoneDetails.push(`${key}: ${value}`);
      }

      // Check if type is MOBILE
      if (!this.mobileInfo.Valid) {
        this.errorMessage = 'Only mobile numbers are allowed. Please enter a valid mobile number.';
        console.error(this.errorMessage);
        this.isValidPhone = false;
      } else {
        this.isValidPhone = true;
      }
    } else {
      this.errorMessage = 'Invalid phone number format.';
      this.isValidPhone = false;
      console.error(this.errorMessage);
    }
  }

  initPersonalForm() {
    this.personalForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      dateOfBirth: ['', [Validators.minLength(6)]],
      gender: [''],
      location: ['', [Validators.required]],
    });
  }

  isControlInvalid(formName: 'accountForm', controlName: string): boolean {
    const control = (this[formName] as FormGroup)?.get(controlName);
    return control?.invalid && control?.touched || false;
  }

  isPersonalFormControlInvalid(formName: 'personalForm', controlName: string): boolean {
    const control = (this[formName] as FormGroup)?.get(controlName);
    return control?.invalid && control?.touched || false;
  }


  onReset() {
    console.log('onReset called in buyer');
    this.locationSelectionComponent.resetLocationForm();
    this.resetServiceCommon.resetForm(this.personalForm);
  }

  onSubmit() {
    console.log(`Form Values for personal form ${JSON.stringify(this.personalForm.value)}`);
    console.log(`Form Values for account form form ${JSON.stringify(this.accountForm.value)}`);
    this.printAllDirtyFields();
    // alert("personalForm " + this.personalForm.dirty + "  accountForm " + this.accountForm.dirty);
    const isChanged = (this.personalForm.dirty || this.accountForm.dirty)

    this.checkFailedFields(); // Custom method to check failed fields
    this.checkVerification();
    if (!this.checkVerification()) {
      return; // Stop submission if verification fails
    }
    /// this.formatDOB();
    if (this.accountForm.valid && this.personalForm.valid && isChanged) {
      alert("Process with saving");
      const combinedData = {
        personal: this.personalForm.value,
        account: this.accountForm.value
      };

      console.log(combinedData);  // Handle the combined form data here
      this.userRegistrationService.saveUserAccountDetails(combinedData).subscribe(
        (success: any) => {

          if (!this.isEditForm) {

            // console.log(`success  ${JSON.stringify(success)}`);
            this.stepperService.setActiveStep(1);  // Change the active step in account-setup
            this.router.navigate(['account-setup/company-details']);
          } else {
            this.toastMessageService.showSuccessMessage('Success', 'The record has been updated successfully.');
          }
        },
        (error: any) => {
          console.error(`success  ${JSON.stringify(error)}`);
          // alert(`Error while saving`);
          if (!this.isEditForm) {
            this.toastMessageService.showErrorMessage('Error', 'An error occurred while updating the record.');
          }

        },
        () => { },

      );

    }
    else if (this.accountForm.valid && this.personalForm.valid && !isChanged) {
      alert("Process without saving");
      this.stepperService.setActiveStep(1);  // Change the active step in account-setup
      this.router.navigate(['account-setup/company-details']);
    }

  }
  locationFormValidators = {
    country: [Validators.required],
    state: [Validators.required],
    city: [Validators.required],
    zipcode: [Validators.required],
  };

  onLocationFormStatus(isLocationFormValid: boolean): void {
    console.log(`onLocationFormStatus ${isLocationFormValid}`);
    if (isLocationFormValid) {
      this.personalForm.controls['location'].setErrors(null);
    } else {
      this.personalForm.controls['location'].setErrors({ invalidLocationForm: true });
    }
  }

  fieldNameMap: { [key: string]: string } = {
    email: 'Email',
    password: 'Password',
    confrimPassword: 'Confirm Password',
    mobile: 'Mobile number',
    firstName: 'First Name',
    lastName: 'Last Name',
    gender: 'Gender',
    dateOfBirth: 'Date of Birth',
    address1: 'Address 1',
    address2: 'Address 2',
    isdCode: 'Country',
    state: 'State',
    city: 'City',
    zip: 'Zip',

  };



  // Custom validator for password strength
  passwordValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (!value) return null;

    const hasUpperCase = /[A-Z]+/.test(value);
    const hasLowerCase = /[a-z]+/.test(value);
    const hasNumeric = /\d+/.test(value);

    const passwordValid = hasUpperCase && hasLowerCase && hasNumeric;
    return !passwordValid ? { passwordInvalid: true } : null;
  }

  // Validator for password confirmation
  passwordsMatchValidator(group: AbstractControl): { [key: string]: boolean } | null {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;

    return password === confirmPassword ? null : { passwordsMismatch: true };
  }

  // Error message generator
  getErrorPersonalMessage(formControlName: string): string {
    const formControl = this.personalForm.get(formControlName);
    const formFieldName = this.fieldNameMap[formControlName] || formControlName;
    // console.log(`formControl ${formControl}`);
    // console.log(`formFieldName ${formFieldName}`)
    // const minLength = formControl!.getError('minlength').requiredLength;

    if (formControl!.hasError('required')) {
      return `${formFieldName} is required`;
    }
    if (formControl!.hasError('email')) {
      return `${formFieldName} is not a valid email`;
    }
    if (formControl!.hasError('passwordInvalid')) {
      return `${formFieldName} must contain at least 1 uppercase, 1 lowercase letter, and 1 number`;
    }
    if (formControl!.hasError('minlength')) {
      if (formControl!.hasError('minlength')) {
        const error = formControl!.getError('minlength');
        if (error) {
          const minLength = error.requiredLength;
          return `${formFieldName} must be at least ${minLength} characters long`;
        }
      }

      //      return `${formFieldName} must be at least ${minLength} characters long`;
    }
    if (formControl!.hasError('passwordsMismatch')) {
      return `Passwords do not match`;
    }
    return ''; // Return empty string if no errors
  }

  getErrorAccountMessage(formControlName: string): string {
    const formControl = this.accountForm.get(formControlName);
    const formFieldName = this.fieldNameMap[formControlName] || formControlName;
    // console.log(`formControl ${formControl}`);
    // console.log(`formFieldName ${formFieldName}`)

    if (formControl!.hasError('required')) {
      return `${formFieldName} is required`;
    }
    if (formControl!.hasError('email')) {
      return `${formFieldName} is not a valid email`;
    }
    if (formControl!.hasError('passwordInvalid')) {
      return `${formFieldName} must contain at least 1 uppercase, 1 lowercase letter, and 1 number`;
    }
    if (formControl!.hasError('minlength')) {
      if (formControl!.hasError('minlength')) {
        const error = formControl!.getError('minlength');
        if (error) {
          const minLength = error.requiredLength;
          return `${formFieldName} must be at least ${minLength} characters long`;
        }
      }

    }
    if (formControl!.hasError('passwordsMismatch')) {
      return `Passwords do not match`;
    }
    return ''; // Return empty string if no errors
  }

  // Custom check for mobile/email verification
  checkVerification(): boolean {
    const isMobileVerified = this.accountForm.get('isMobileVerified')?.value;
    const isEmailVerified = this.accountForm.get('isEmailVerified')?.value;

    if (!isMobileVerified || !isEmailVerified) {
      alert('Please verify your mobile and email first.');
      return false; // Return false if verification fails
    }
    return true; // Return true if both are verified
  }


  // Method to check which fields failed validation
  checkFailedFields() {
    Object.keys(this.accountForm.controls).forEach(field => {
      const control = this.accountForm.get(field);
      if (control?.invalid) {
        console.log(`${field} failed due to:`, control.errors);
      }
    });
    Object.keys(this.personalForm.controls).forEach(field => {
      const control = this.personalForm.get(field);
      if (control?.invalid) {
        console.log(`${field} failed due to:`, control.errors);
      }
    });
  }

  formatDOB(): void {
    const dobControl = this.personalForm.get('dateOfBirth');
    //  alert(`dobControl1 ${dobControl}`)//

    if (dobControl) {
      //   alert(`dobControl2 ${dobControl}`)

      const dobValue = dobControl.value;

      // Check if the dobValue is a string or Date object
      let dateObj: Date;
      if (typeof dobValue === 'string' || dobValue instanceof Date) {
        //   alert(`dateObj ${dateObj}`)

        dateObj = new Date(dobValue);

        // Ensure the date is valid
        if (!isNaN(dateObj.getTime())) {
          // alert(`isNaN ${isNaN(dateObj.getTime())}`)

          // Adjust the date to ensure timezone does not shift the date
          const year = dateObj.getUTCFullYear();
          const month = ('0' + (dateObj.getUTCMonth() + 1)).slice(-2); // Months are 0-indexed
          const day = ('0' + dateObj.getUTCDate()).slice(-2);

          // Format the date as YYYY-MM-DD (ISO 8601 format)
          const formattedDate = `${year}-${month}-${day}`;
          console.log(`formattedDate date is ${formattedDate}`)
          // Set the formatted date back to the form control
          dobControl.setValue(formattedDate);
        }
      }
    }
  }
  data: any;



  // Method to patch the form with provided data
  patchForms(data: any) {
    // Patch account form
    //    alert(`Patch Forms ${JSON.stringify(data)}`);  
    if (data.account) {
      this.accountForm.patchValue({
        email: data.account.email,
        password: data.account.password,
        confirmPassword: data.account.confirmPassword,
        isdCode: data.account.isdCode,
        mobile: data.account.mobile,
        isMobileVerified: data.account.isMobileVerified,
        isEmailVerified: data.account.isEmailVerified,
        socialLogin: data.account.socialLogin
      });

      // Set the mobile field readonly if not blank
      const mobileControl = this.accountForm.get('mobile');
      const isdCodeControl = this.accountForm.get('isdCode');
      const emailControl = this.accountForm.get('email');
      if (data.account.mobile) {
        mobileControl?.disable(); // Set as readonly
        isdCodeControl?.disable();
        emailControl?.disable();
      } else {
        mobileControl?.enable(); // Set as writable
        isdCodeControl?.enable();
        emailControl?.enable();
      }
    }


    // Patch personal form
    if (data.personal) {
      // Convert timestamp to Date object before patching it into the form
      const dateOfBirth = new Date(data.personal.dateOfBirth);
      this.personalForm.patchValue({
        firstName: data.personal.firstName,
        lastName: data.personal.lastName,
        dateOfBirth: dateOfBirth, // Set the date properly
        gender: data.personal.gender,
        location: data.personal.location
      });


    }
  }

  // Method to fetch account details from the backend if no query params or session data
  fetchAccountDetailsFromService() {

    this.route.data.subscribe((data) => {
      const accountDetails = data['accountDetails'];
      this.existingLocationData = accountDetails.personal.location;

      this.patchForms(accountDetails);
      this.isMobileReadonly = true;
      this.isEmailReadonly = true;
      this.removeValidators();

    });
    /*  this.userRegistrationService.getAccountDetails().subscribe(
       (response) => {
         console.log('Fetched account details from service:', response);
         // Patch the forms with the data from the service
         this.patchForms(response);
         this.isMobileReadonly = true;
         this.isEmailReadonly = true;
         this.removeValidators();
       },
       (error) => {
         console.error('Error fetching account details from service:', error);
       }
     ); */
  }

  // Method to convert timestamp (milliseconds) to a valid date format (YYYY-MM-DD)
  convertTimestampToDate(timestamp: number): string {
    const date = new Date(timestamp);
    return date.toISOString().split('T')[0]; // Converts to 'YYYY-MM-DD' format
  }


  // Helper method for validation messages
  public get actForm() {
    return this.accountForm.controls;
  }

  public get prsnlForm() {
    return this.accountForm.controls;
  }

  // In your component class where accountForm is defined
  removeValidators() {
    // Remove required validator from password
    this.accountForm.get('password')?.setValidators([]);

    // Remove required validator from confirmPassword
    this.accountForm.get('confirmPassword')?.setValidators([]);

    // Remove required validator from isMobileVerified
    this.accountForm.get('isMobileVerified')?.setValidators([]);

    // Remove required validator from isEmailVerified
    this.accountForm.get('isEmailVerified')?.setValidators([]);

    /* 
        this.accountForm.get('mobile')?.disable();
        this.accountForm.get('mobile')?.updateValueAndValidity();
    
        this.accountForm.get('email')?.disable();
        this.accountForm.get('email')?.updateValueAndValidity(); */


    // Update the validity of the controls
    this.accountForm.get('password')?.updateValueAndValidity();

    this.accountForm.get('confirmPassword')?.updateValueAndValidity();
    this.accountForm.get('isMobileVerified')?.updateValueAndValidity();
    this.accountForm.get('isEmailVerified')?.updateValueAndValidity();
  }

  onCountriesLoaded(): void {
    ///alert('Countries have been loaded!');
    // Add any additional logic you want to execute when countries are loaded.
  }


  onCitiesLoaded(): void {
    //  alert('Cities have been loaded!');
    // Add any additional logic you want to execute when countries are loaded.
  }

  onStatesLoaded(): void {
    //  alert('States have been loaded!');
    // Add any additional logic you want to execute when countries are loaded.
  }

  printDirtyFields(formGroup: FormGroup | FormArray, path: string = '') {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      const controlPath = path ? `${path}.${key}` : key;

      if (control instanceof FormGroup || control instanceof FormArray) {
        this.printDirtyFields(control, controlPath); // Recursive call for nested form groups/arrays
      } else if (control instanceof FormControl && control.dirty) {
        console.log(`Dirty field: ${controlPath} with value: ${JSON.stringify(control.value)}`);
      }
    });
  }

  // Example function to manually trigger printing dirty fields
  printAllDirtyFields() {
    this.printDirtyFields(this.accountForm);
    this.printDirtyFields(this.personalForm);
  }

  /*fetchCountries(): void {
    // this.isCountryLoading = true;

    this.locationService.getCountriesNEW().subscribe(
      (countries) => {
        this.countries = countries;
        // Emit the event here when countries are loaded
      console.log(` this.prepopulateData.account.isdCode ${ this.prepopulateData.account.isdCode}`); 
        if (this.prepopulateData && this.prepopulateData.account && this.prepopulateData.account.isdCode) {
          //console.log(`existingLocationData Country are ${JSON.stringify(this.existingLocationData.country)}`);
          this.selectedCountry = this.countries.find(
            (country) => {
              return country.iso2 === this.prepopulateData.account.isdCode;
            }
          );
          console.log(`this.selectedCountry ${this.selectedCountry}`)
          if (this.selectedCountry) {
            // this.selectedCountry = country;
            this.accountForm.patchValue({ isdCode: this.selectedCountry });

          } else {
          }
        }
      },
      (error) => console.log(JSON.stringify(error)),
      () => {

      }
    );
  } */

  fetchCountries(): void {
    // console.log('Fetching countries...');

    this.locationService.getCountriesNEW().subscribe(
      (countries) => {
        // console.log('Countries loaded:', countries);
        this.countries = countries;

        if (this.prepopulateData?.account?.isdCode) {
          //  console.log('PrepopulateData ISD Code:', this.prepopulateData.account.isdCode);
          const isdCodeToPrepopulate = this.prepopulateData.account.isdCode;

          // Find the matching country
          this.selectedCountry = this.countries.find((country) => {
            //console.log(`Checking country: ${country.name} with iso2: ${country.iso2}`);
            return country.isdCode === isdCodeToPrepopulate;
          });

          if (this.selectedCountry) {
            //console.log('Selected country found:', this.selectedCountry);
            this.accountForm.patchValue({ isdCode: this.selectedCountry });
            //console.log('Form value after patching:', this.accountForm.value);
          } else {
            // console.warn('No matching country found for ISD Code:', isdCodeToPrepopulate);
          }
        } else {
          //console.warn('No ISD Code found in prepopulateData.');
        }
      },
      (error) => {
        //console.error('Error fetching countries:', error);
      },
      () => {
        // console.log('Country fetching process completed.');
      }
    );
  }



  getOTP() {
    const phoneNumber = (this.accountForm?.get('mobile')?.value ?? '');
    const isdCode = (this.accountForm?.get('isdCode')?.value?.isdCode ?? '');
    this.userRegistrationService.sendOTP(phoneNumber, isdCode).subscribe(
      (success: any) => {
        this.showOTPDialog = true;
        this.toastMessageService.showSuccessMessage('OTP Sent', success.message);
        this.currentOtpAttempt++;

        this.startCountdown();
      },
      (error: any) => {
      },
      () => { }

    )
  }

  resendOTP() {
    if (this.currentOtpAttempt < this.maxOtpAttempts) {
      this.currentOtpAttempt++;
      this.getOTP(); // Send OTP
      this.startCountdown(); // Restart timer
    } else {
      console.log('Maximum OTP attempts reached.');
      this.toastMessageService.showSuccessMessage('Please try again later', 'You have reached the maximum number of OTP resend attempts.');

    }
  }

  editMobile() {
    this.accountForm.patchValue({ isMobileVerified: false });
    this.accountForm.get('mobile').enable();
    this.accountForm.get('isdCode').enable();
  }

  customFilterFunction(event: KeyboardEvent, options: DropdownFilterOptions) {
    options.filter(event);
  }

  resetFunction(options: DropdownFilterOptions) {
    options.reset();
    this.filterValue = '';
  }

  verifyOTP() {
    const mobile = (this.accountForm?.get('mobile')?.value ?? '');
    const isdCode = (this.accountForm?.get('isdCode')?.value?.isdCode ?? '');
    this.userRegistrationService.validateOTP(mobile, this.otp, isdCode).subscribe(
      (success: any) => {
        console.log(`Successfully validated `);
        this.toastMessageService.showSuccessMessage('OTP Validated', success.message);
        this.showOTPDialog = false;
        this.accountForm.get('isMobileVerified')?.setValue(true);
        this.accountForm.get('mobile').disable();
        this.accountForm.get('isdCode').disable();
        this.otp = null;
      },
      (error: any) => {
        this.toastMessageService.showErrorMessage('OTP is Invalid', 'Invalid OTP.');
        console.log(`Faile to validate `);
        console.log(`Faile to validate `);
        this.otp = null;
      },
      () => { },

    )
  }

  maxOtpAttempts: number = 5; // Maximum allowed attempts
  currentOtpAttempt: number = 0; // Initial attempt
  countdown: number = 90; // 90-second timer
  isTimerActive: boolean = true;
  // Start countdown timer
  startCountdown() {
    this.isTimerActive = true;
    this.countdown = 90;
    const timer = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(timer);
        this.isTimerActive = false;
      }
    }, 1000);
  }

  isEditForm = false;
  checkPathForEditForm(): void {
    if (this.router.url === '/edit/account-details') {
      console.log('Path /edit/account-details is accessed');
      this.isEditForm = true;
      // Add any logic needed when this path is accessed
    }
  }

  changePassword() {
    this.changePasswordForm.patchValue({ oldPassword: '', newPassword: '', confirmPassword: '' });
    this.displayDialog = true;
  }

  // Check if passwords match
  get passwordsMismatch(): boolean {
    return (
      this.changePasswordForm.get('newPassword')?.value !==
      this.changePasswordForm.get('confirmPassword')?.value
    );
  }

  onChangePasswordSubmit(): void {
    if (this.changePasswordForm.invalid || this.passwordsMismatch) {
      return;
    }

    const { oldPassword, newPassword } = this.changePasswordForm.value;
    this.userRegistrationService.changePasswordService(this.changePasswordForm.value).subscribe(
      (success: any) => {
        this.toastMessageService.showInfoMessage('Success', success.result);
        this.displayDialog = false; // Close dialog on success
        this.passwordChangeMessages = [{ severity: 'success', detail: success.result }];
        this.changePasswordForm.patchValue({ oldPassword: '', newPassword: '', confirmPassword: '' });
      },
      (error: any) => {
        this.toastMessageService.showErrorMessage('Error', error.error);
        this.passwordChangeMessages = [{ severity: 'error', detail: error.error }];
      }
    )

  }

}
