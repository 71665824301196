<p-tabView class="full-width" [(activeIndex)]="activeTabIndex" (activeIndexChange)="onTabChange($event)">
    <p-tabPanel header="Subscription">
        <ng-template pTemplate="content">
            <app-subscription-opted></app-subscription-opted>
        </ng-template>
    </p-tabPanel>
    <!--   <p-tabPanel header="Features">
        <ng-template pTemplate="content">
            <app-subscription-opted-features></app-subscription-opted-features>
        </ng-template>
    </p-tabPanel> -->
    <p-tabPanel header="Transactions">
        <ng-template pTemplate="content">
            <app-transaction-history></app-transaction-history>
        </ng-template>
    </p-tabPanel>

    <p-tabPanel header="Purchase">
        <ng-template pTemplate="content">
            <app-subscription-package></app-subscription-package>
        </ng-template>
    </p-tabPanel>
</p-tabView>