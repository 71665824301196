import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClipboardModule } from 'ngx-clipboard';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxChangeEvent, CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FloatLabelModule } from 'primeng/floatlabel';
import { IconFieldModule } from 'primeng/iconfield';
import { InplaceModule } from 'primeng/inplace';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputIconModule } from 'primeng/inputicon';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SpeedDialModule } from 'primeng/speeddial';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { ResetCommonService } from 'src/app/commons/services/reset-common.service';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { CommonsModule } from 'src/app/modules/commons/commons.module';
import { LocationSelectionComponent } from 'src/app/modules/commons/location-selection/location-selection.component';
import { UserRegistrationService } from 'src/app/modules/extras/services/user-registration.service';
import { AccountSetupStepperService } from 'src/app/services/tally-sheet/account-setup-stepper.service';

@Component({
  selector: 'app-personal-company-details',
  standalone: true,
  imports: [FormsModule, CommonModule, ReactiveFormsModule, ButtonModule, BlockUIModule, PanelModule, ProgressBarModule, InplaceModule,
    InputGroupModule, InputGroupAddonModule, ToastModule, DialogModule, ProgressSpinnerModule, ConfirmDialogModule, CardModule,
    ToggleButtonModule, InputNumberModule, TooltipModule, MultiSelectModule, ToolbarModule, AvatarModule,
    TableModule, SpeedDialModule, AccordionModule, BadgeModule, ConfirmPopupModule, InputSwitchModule,
    ClipboardModule, DividerModule, FieldsetModule, PasswordModule, IconFieldModule, InputIconModule, InputTextModule, FloatLabelModule,
    CalendarModule, RadioButtonModule, DropdownModule, CommonsModule, CheckboxModule
  ],
  templateUrl: './company-details.component.html',
  styleUrl: './company-details.component.scss'
})
export class CompanyDetailsComponent implements OnInit, AfterViewInit {
  // locationSelectionComponent!: LocationSelectionComponent;
  @Input() isSubmittedFromSidebar: boolean = false;
  existingLocationData!: Location;
  @ViewChild('locationSelection') locationSelectionComponent!: LocationSelectionComponent;


  companyForm!: FormGroup;

  companySizeOptions = [
    '1-10 employees',
    '11-50 employees',
    '51-200 employees',
    '201-500 employees',
    '500+ employees',
  ];


  roleOptions = [
    'CEO / Founder',
    'CTO / Technical Head',
    'COO / Operations Head',
    'CFO / Finance Head',
    'CMO / Marketing Head',
    'Manager / Team Lead',
    'HR / People Operations',
    'Engineer / Developer',
    'Designer',
    'Consultant',
    'Analyst / Data Scientist',
    'Sales / Business Development',
    'Intern',
    'Other'
  ];


  legalEntityOptions = [
    'Private Limited',
    'Public Limited',
    'Partnership',
    'Proprietorship',
    'Corporation (Inc)',
    'LLP',
    'LLC',
    'Other'
  ];

  showNativeCalendar = false;

  industryTypeOptions: any[] = [
    'Sawmiller',
    'Exporter',
    'Importer',
    'Trader',
    'Commission Agent',
    'Wholesaler',
    'Retailer',
    'Other'
  ];

  dummy!: { name: string; code: string; }[];

  constructor(
    private fb: FormBuilder,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private stepperService: AccountSetupStepperService,
    private resetServiceCommon: ResetCommonService,
    private userRegistrationService: UserRegistrationService,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessagesService
  ) {

    const isMobile = this.deviceService.isMobile();
    if (isMobile) {
      this.showNativeCalendar = true;
    }
  }

  ngAfterViewInit(): void {
    this.stepperService.setActiveStep(1);  // Change the active step in account-setup
    if (this.existingLocationData) {
      if (this.locationSelectionComponent) {
        // alert(`2`);
        this.locationSelectionComponent.markAllControlsAsPristine();
        const locationControl = this.companyForm.get('location');
        if (locationControl) {
          locationControl.markAsPristine({ onlySelf: false });
        }
      }
    }
  }


  ngOnInit() {
    this.checkPathForEditForm();
    this.initCompanyForm();
    this.initCompanyDetailsFromService();

  }

  initCompanyForm() {
    this.companyForm = this.fb.group({
      id: [],
      companyId: [],
      companyName: ['', [Validators.required, Validators.minLength(3)]],
      taxId: ['', [Validators.minLength(3)]],
      location: ['', [Validators.required]],
      legalEntityType: [''], //
      companySize: [''], //
      roleInCompany: [''], // industryType
      industryType: [''],
      isIndividual: [],
    });
  }

  isControlInvalid(formName: 'companyForm', controlName: string): boolean {
    const control = (this[formName] as FormGroup)?.get(controlName);
    return control?.invalid && control?.touched || false;
  }

  fieldNameMap: { [key: string]: string } = {
    companyName: 'Company Name',
    taxId: 'Tax ID',
    address1: 'Address 1',
    address2: 'Address 2',
    country: 'Country',
    state: 'State',
    city: 'City',
    zip: 'Zip',
    legalEntityType: 'Legal Entity Type',
    companySize: 'Company Size',
    roleInCompany: 'Role in a Company',
    industryType: 'Industry Type',


  };

  // Error message generator
  getErrorMessage(formControlName: string): string {
    const formControl = this.companyForm.get(formControlName);
    const formFieldName = this.fieldNameMap[formControlName] || formControlName;

    if (formControl!.hasError('required')) {
      return `${formFieldName} is required`;
    }
    if (formControl!.hasError('email')) {
      return `${formFieldName} is not a valid email`;
    }
    if (formControl!.hasError('passwordInvalid')) {
      return `${formFieldName} must contain at least 1 uppercase, 1 lowercase letter, and 1 number`;
    }
    if (formControl!.hasError('minlength')) {
      return `${formFieldName} must be at least 8 characters long`;
    }
    if (formControl!.hasError('passwordsMismatch')) {
      return `Passwords do not match`;
    }
    return ''; // Return empty string if no errors
  }

  // Method to check which fields failed validation
  checkFailedFields() {
    Object.keys(this.companyForm.controls).forEach(field => {
      const control = this.companyForm.get(field);
      if (control?.invalid) {
        console.log(`${field} failed due to:`, control.errors);
      }
    });
  }

  onSubmit(navigationPoistion?: 'next' | 'previous') {

    const data = this.companyForm.value;
    console.log(data);
    this.checkFailedFields(); // Custom method to check failed fields
    const isChanged = this.companyForm.dirty;
    console.log(`isChanged ${isChanged} this.companyForm.valid ${this.companyForm.valid}`);
    //this.checkFailedFields(); // Custom method to check failed fields
    /// this.formatDOB();
    if (this.companyForm.valid && isChanged) {
      console.log(this.companyForm);  // Handle the combined form data here
      this.userRegistrationService.saveCompanyDetails(data).subscribe(
        (success: any) => {
          // console.log(`success saveCompanyDetails ${JSON.stringify(data)} `);
          // Change the active step in account-setup
          if (navigationPoistion === 'previous') {
            this.stepperService.setActiveStep(0);
            this.router.navigate(['account-setup/account-details']);
          }
          else if (navigationPoistion === 'next') {
            this.stepperService.setActiveStep(1);
            this.router.navigate(['account-setup/subscription']);
          } else {

          }
        },
        (error: any) => {
          this.toastMessageService.showErrorMessage('Error', 'An error occurred while updating the record.');
        },
        () => { },

      )

    }
    else if (this.companyForm.valid && !isChanged) {
      if (navigationPoistion === 'previous') {
        this.stepperService.setActiveStep(0);
        this.router.navigate(['account-setup/account-details']);
      }
      if (navigationPoistion === 'next') {
        this.stepperService.setActiveStep(1);
        this.router.navigate(['account-setup/subscription']);
      }
    }
  }

  onUpdate(): void {
    const data = this.companyForm.value;
    console.log(data);
    this.checkFailedFields(); // Custom method to check failed fields
    const isChanged = this.companyForm.dirty;
    console.log(`isChanged ${isChanged} this.companyForm.valid ${this.companyForm.valid}`);
    //this.checkFailedFields(); // Custom method to check failed fields
    /// this.formatDOB();
    if (this.companyForm.valid && isChanged) {
      console.log(this.companyForm);  // Handle the combined form data here
      this.userRegistrationService.updateCompanyDetails(data).subscribe(
        (success: any) => {
          //          console.log(`success onUpdate ${JSON.stringify(data)} `);
          // Change the active step in account-setup
          this.toastMessageService.showSuccessMessage('Success', 'The record has been updated successfully.');
        },
        (error: any) => {
          this.toastMessageService.showErrorMessage('Error', 'An error occurred while updating the record.');
        },
        () => { },

      )
    }

  }


  onReset() {
    console.log('onReset called in buyer');
    this.locationSelectionComponent.resetLocationForm();
    this.resetServiceCommon.resetForm(this.companyForm);
  }

  locationFormValidators = {
    country: [Validators.required],
    state: [Validators.required],
    city: [Validators.required],
    zipcode: [Validators.required],
  };

  initCompanyDetailsFromService() {
    this.route.data.subscribe((data) => {
      //alert(`1`);
      const companyDetails = data['companyDetails'];
      if (companyDetails.isIndividual) {
        // alert(`isIndividual`);
        this.removeValidatorsIsIndividual();
      }
      this.companyForm.patchValue(companyDetails);
      if (companyDetails.location) {
        // alert(response.location)
        this.companyForm.patchValue({ location: companyDetails.location });
        this.existingLocationData = companyDetails.location;
      }
    });
    /* this.userRegistrationService.getCompanyDetails().subscribe(
      (response: any) => {
        console.log(`response ${JSON.stringify(response)}`);
        if (response.isIndividual) {
          // alert(`isIndividual`);
          this.removeValidatorsIsIndividual();
        }
        this.companyForm.patchValue(response);
        if (response.location) {
          // alert(response.location)
          this.companyForm.patchValue({ location: response.location });
          this.existingLocationData = response.location;

          setTimeout(() => {
            const locationControl = this.companyForm.get('location');
            if (locationControl) {
              //   locationControl.reset(); // Reset the form values
              locationControl.markAsPristine(); // Mark the form as pristine
            }
          }, 1000);
        }
      },
      (error: any) => { },
      () => { },
    ) */
  }

  onLocationFormStatus(isLocationFormValid: boolean): void {
    console.log(`onLocationFormStatus ${isLocationFormValid}`);
    if (isLocationFormValid) {
      this.companyForm.controls['location'].setErrors(null);
    } else {
      this.companyForm.controls['location'].setErrors({ invalidLocationForm: true });
    }
  }

  toggleIsIndividual(event: CheckboxChangeEvent) {
    const isChecked = event.checked;
    if (isChecked) {
      this.removeValidatorsIsIndividual();
    } else {
      this.setValidatorsIsIndividual();

    }
  }

  removeValidatorsIsIndividual() {
    // Remove required validator from password
    this.companyForm.get('companyName')?.setValidators([]);
    this.companyForm.get('taxId')?.setValidators([]);
    this.companyForm.get('location')?.setValidators([]);
    this.companyForm.get('legalEntityType')?.setValidators([]);

    this.companyForm.get('companySize')?.setValidators([]);
    this.companyForm.get('roleInCompany')?.setValidators([]);
    this.companyForm.get('industryType')?.setValidators([]);
    this.companyForm.get('isIndividual')?.setValidators([]);


    this.companyForm.get('companyName')?.disable();
    this.companyForm.get('taxId')?.disable();
    this.companyForm.get('location')?.disable();
    this.companyForm.get('legalEntityType')?.disable();

    this.companyForm.get('companySize')?.disable();
    this.companyForm.get('roleInCompany')?.disable();
    this.companyForm.get('industryType')?.disable();

    this.companyForm.get('companyName')?.updateValueAndValidity();
    this.companyForm.get('taxId')?.updateValueAndValidity();
    this.companyForm.get('location')?.updateValueAndValidity();
    this.companyForm.get('legalEntityType')?.updateValueAndValidity();

    this.companyForm.get('companySize')?.updateValueAndValidity();
    this.companyForm.get('roleInCompany')?.updateValueAndValidity();
    this.companyForm.get('industryType')?.updateValueAndValidity();

    const locationControl = this.companyForm.get('location');
    if (locationControl) {
      //   locationControl.reset(); // Reset the form values
      locationControl.markAsPristine(); // Mark the form as pristine
    }
    /* 
        this.accountForm.get('mobile')?.disable();
        this.accountForm.get('mobile')?.updateValueAndValidity();
    
        this.accountForm.get('email')?.disable();
        this.accountForm.get('email')?.updateValueAndValidity(); */


    // Update the validity of the controls
    this.companyForm.get('password')?.updateValueAndValidity();

    this.companyForm.get('confirmPassword')?.updateValueAndValidity();
    this.companyForm.get('isMobileVerified')?.updateValueAndValidity();
    this.companyForm.get('isEmailVerified')?.updateValueAndValidity();

    this.locationSelectionComponent.locationForm.disable();

  }

  setValidatorsIsIndividual() {
    // Set validators for each form control
    this.companyForm.get('companyName')?.setValidators([Validators.required, Validators.minLength(3)]);
    this.companyForm.get('taxId')?.setValidators([Validators.minLength(3)]);
    this.companyForm.get('location')?.setValidators([Validators.required]);
    this.companyForm.get('legalEntityType')?.setValidators([]); // Add any necessary validators
    this.companyForm.get('companySize')?.setValidators([]); // Add any necessary validators
    this.companyForm.get('roleInCompany')?.setValidators([]); // Add any necessary validators
    this.companyForm.get('industryType')?.setValidators([]); // Add any necessary validators
    this.companyForm.get('isIndividual')?.setValidators([]); // Add any necessary validators

    // Enable all the controls
    this.companyForm.get('companyName')?.enable();
    this.companyForm.get('taxId')?.enable();
    this.companyForm.get('location')?.enable();
    this.companyForm.get('legalEntityType')?.enable();
    this.companyForm.get('companySize')?.enable();
    this.companyForm.get('roleInCompany')?.enable();
    this.companyForm.get('industryType')?.enable();

    // Update the validity of the controls
    this.companyForm.get('companyName')?.updateValueAndValidity();
    this.companyForm.get('taxId')?.updateValueAndValidity();
    this.companyForm.get('location')?.updateValueAndValidity();
    this.companyForm.get('legalEntityType')?.updateValueAndValidity();
    this.companyForm.get('companySize')?.updateValueAndValidity();
    this.companyForm.get('roleInCompany')?.updateValueAndValidity();
    this.companyForm.get('industryType')?.updateValueAndValidity();

    const locationControl = this.companyForm.get('location');
    if (locationControl) {
      locationControl.markAsDirty(); // Mark the form as dirty
    }

    // Update the validity of other relevant controls
    this.companyForm.get('password')?.updateValueAndValidity();
    this.companyForm.get('confirmPassword')?.updateValueAndValidity();
    this.companyForm.get('isMobileVerified')?.updateValueAndValidity();
    this.companyForm.get('isEmailVerified')?.updateValueAndValidity();

    this.locationSelectionComponent.locationForm.enable();
  }



  // Helper method for validation messages
  public get cmpnyForm() {
    return this.companyForm.controls;
  }


  isEditForm = false;
  checkPathForEditForm(): void {
    if (this.router.url === '/edit/company') {
      console.log('Path /edit/company is accessed');
      this.isEditForm = true;
      // Add any logic needed when this path is accessed
    }
  }


}
