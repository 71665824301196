import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SideBarMenuService } from '../services/side-bar-menu.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  // The JSON string with roles
  userProfile: any;
  constructor(private router: Router,
    private sideBarMenu: SideBarMenuService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      this.userProfile = await this.sideBarMenu.getDecryptedUserProfile();
      console.log('User profile updated successfully in role guard:', JSON.stringify(this.userProfile));
      console.log(`Checking if user has access to ${route.data['roles']}`);
      const requiredRoles = route.data['roles'] as Array<string>;
      const userRoles = this.userProfile.roles;

      const hasAccess = requiredRoles.some(role => {
        const hasRole = userRoles.includes(role);
        console.log(`Role Compare: checking if userRoles includes ${role} - ${hasRole}`);
        return hasRole;
      });

      if (!hasAccess) {
        this.router.navigate(['/unauthorized']); // Navigate to unauthorized page
        return false;
      }

      return true;
    } catch (error) {
      console.error('Error getting decrypted profile:', error);
      return false;
    }
  }
}
