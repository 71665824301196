import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../services/tally-sheet/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class TallysheetHttpInterceptorService implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone({ withCredentials: true });

    return next.handle(clonedRequest).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          console.log('Interceptor :: Response:', event);
        }
      }),
      catchError((error: any) => {
        console.error('Error intercepted:', error);
        if (error.status === 401) {
         // alert('You are not logged in or your session has expired.');
        } else if (error.status === 500) {
          //alert('Internal server error. Please try again later.');
        }
        // Re-throw the original error with its complete structure
        return throwError(() => error);
      })
    );
  }
}
/*
@Injectable()
export class TallysheetHttpInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the token from AuthService

    // Clone the request to modify the headers

    let clonedRequest = req.clone({
      withCredentials: true // Include credentials
    });

    // Log the headers to the console (optional for debugging)
    const headersInfo = clonedRequest.headers.keys().map(key => {
      const headerString = `${key}: ${clonedRequest.headers.get(key)}`;
      console.log(headerString);  // This logs each header.
      return headerString;        // This returns the header string to the new array.
    });

    console.log('Interceptor :: Request Headers:', headersInfo);


    // Check if the method is POST and log the body
    if (req.method === 'POST') {
      console.log('POST Request Body:', req.body);
    }

    // Pass the cloned request to the next handler
    return next.handle(clonedRequest).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // Log the response to the console
          console.log('Interceptor :: Response:', event);
        }
      }),
      catchError((error: any) => {
        console.error(`Error is ${JSON.stringify(error.error)}`);
        let errorMessage = '';

        // Client-side error
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Client Error: ${error.error.message}`;
        }
        // Server-side error
        else {
          errorMessage = `Server Error: ${error.status} - ${error.message}`;
        }

        const err = error.error;
        let count = 0;
        if (err) {
          const httpStatusCode = err.httpStatusCode;
          const httpStatus = err.httpStatus;
          const url = err.url;
          const data = err.data;
          if (error.status === 0) {
            // Trigger an alert when the connection is refused
            if (count === 0) {
              alert('Connection refused: Please check your network or server is down.');
            }
            count++;
          }
          // console.error('Error occurred:', JSON.stringify(err));
          console.error(`httpStatusCode {} httpStatus {} url{} data {}`, httpStatusCode, httpStatus, url, JSON.stringify(data))
          this.router.navigate([url], { queryParams: { data: JSON.stringify(data) } });

          // Custom logic for specific error codes
          if (error.status === 403) {
            //  this.router.navigateByUrl(url);
          } else if (error.status === 401) {
            // this.router.navigate(['/login']);
          }
        }
        // You can throw the error or return a default fallback response
        // return throwError(() => error);
        return of(null); // or return of({}); if you want to return an empty object

      })
    );
  }
}
*/