<!-- <p-toast></p-toast> -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>

<!-- <div class="pb-3">
    <p-breadcrumb [model]="breadCumbItems" [home]="home"></p-breadcrumb>
</div> -->

<p-card>
    <div class="formgrid grid col-8">

        <div class="field col-8">
            <p> Customer Code :</p>
        </div>
        <div class="field col-4">
            <p> {{ subscriptionOpted?.companyId?? '' }}</p>
        </div>


        <div class="field col-8">
            <p> Current Subscription Name :</p>
        </div>
        <div class="field col-4">
            <p> {{ subscriptionOpted?.currentPackageName?? '' }}</p>
        </div>

        <div class="field col-8">
            <p> Current Subscription Valid Till :</p>
        </div>
        <div class="field col-4">
            <p> {{ subscriptionOpted?.currentPackageValidTill?.dateTime?? ''  }}</p>
        </div>


        <div class="field col-8">
            <p> Last Payment Made On:</p>
        </div>
        <div class="field col-4">
            <p> {{ subscriptionOpted?.lastPaymentMadeOn?.dateTime?? '' }}</p>
        </div>

        <div class="field col-8">
            <p>Payment Due On :</p>
        </div>
        <div class="field col-4">
            <p> {{ subscriptionOpted?.nextPaymentDueOn?.dateTime?? ''  }}</p>
        </div>

        <div class="field col-8">
            <p>Paid Till:</p>
        </div>
        <div class="field col-4">
            <p> {{ subscriptionOpted?.paymentTill?.dateTime?? ''  }}</p>
        </div>

        <!-- <div class="field col-12 md:col-6">
            <p> Payment Status :</p>
        </div>
        <div class="field col-12 md:col-6">
            <p> {{ subscriptionOpted?.paymentStatus?? ''}}</p>
        </div> -->

        <div class="field col-8">
            <p> Subscription Status :</p>
        </div>
        <div class="field col-4">
            <p class="text-green-400" *ngIf="subscriptionOpted?.status; else subscriptionIsNotActive"> 
                {{  subscriptionOpted?.status?? ''}}
            </p>
            <ng-template #subscriptionIsNotActive>
                <p class="text-red-400"> SUSPENDED </p>
            </ng-template>
        </div>


    </div>
    <!-- <ng-template pTemplate="footer">
            <p-button [label]="addEditButton" [disabled]="!myForm.dirty" icon="pi pi-check" type="submit"></p-button>
            <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary"
                [style]="{'margin-left': '.5em'}"></p-button>
        </ng-template> -->
    <div  class="grid col-12 width: 95vw;"
    class="text-xs  text-justify sm:w-9 md:w-9 lg:w-6 xl:w-6 surface-overlay border-round border-0 border-gray-500 font-normal">
        <!-- class="text-xs sm:w-9 md:w-9 lg:w-6 xl:w-6 surface-overlay border-round border-1 border-gray-500 font-normal"> -->
        <p class="p-1 justify-content-evenly "> <span>*</span>
            Please note that our system operates based on Coordinated Universal Time (UTC). Therefore, the time of your
            subscription's activation and expiration will be calculated based on this time zone. If you are in a
            different
            time zone, there may be a perceived difference in the timing of your subscription's start and end dates
        </p>
    </div>
</p-card>