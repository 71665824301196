import { Component, OnInit } from '@angular/core';
import { SHARED_MODULES } from '../../shared-imports';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { PasswordService } from 'src/app/services/password.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AppConstants } from 'src/app/commons/app-constants';
import { AppRoutePathConstants } from 'src/app/commons/app-route-path-constants';

@Component({
  selector: 'app-password-reset',
  standalone: true,
  imports: [SHARED_MODULES],
  templateUrl: './password-reset.component.html',
  styleUrl: './password-reset.component.css'
})
export class PasswordResetComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  resetPasswordForm: FormGroup;
  isResetMode: boolean = false;
  passwordMismatch = false; // Flag for password mismatch


  constructor(
    private fb: FormBuilder,
    private passwordService: PasswordService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.resetPasswordForm = this.fb.group({
      token: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    // Extract token from URL
    const token = this.route.snapshot.queryParamMap.get('token');
    if (token) {
      this.isResetMode = true;
      this.resetPasswordForm.get('token')?.setValue(token);
    }
    this.resetPasswordForm.get('newPassword')?.valueChanges.subscribe(() => this.checkPasswordMatch());
    this.resetPasswordForm.get('confirmPassword')?.valueChanges.subscribe(() => this.checkPasswordMatch());
  }

  checkPasswordMatch(): void {
    const newPassword = this.resetPasswordForm.get('newPassword')?.value;
    const confirmPassword = this.resetPasswordForm.get('confirmPassword')?.value;

    this.passwordMismatch = newPassword !== confirmPassword && !!confirmPassword; // Only show if confirmPassword is not empty
  }


  sendResetLink() {
    if (this.forgotPasswordForm.invalid) return;

    const email = this.forgotPasswordForm.value.email;
    this.passwordService.sendResetLink(email).subscribe({
      next: () => {
        this.messageService.add({ severity: 'success', summary: 'Email Sent', detail: 'Password reset link has been sent to your email.' });
      },
      error: () => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to send reset link. Please try again.' });
      }
    });
  }

  resetPassword() {
    if (this.resetPasswordForm.invalid) return;

    const { token, newPassword } = this.resetPasswordForm.value;
    this.passwordService.resetPassword(token, newPassword).subscribe({
      next: () => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Password reset successfully.' });
        this.router.navigate([AppRoutePathConstants.PATH_LOGIN])
      },
      error: () => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid token or password. Please try again.' });
      }
    });
  }
}
