<div class="flex lg:justify-content-center flex-wrap m-0 p-0 md:p-1">


   <p-fieldset legend="Account Details" class="col-12 md:col-9 p-0 md:p-1 m-0 p-0 md:p-1">
      <form [formGroup]="accountForm" (ngSubmit)="onSubmit()" class="p-fluid">
         <!-- <p-dropdown [options]="countries" formControlName="country" optionLabel="name" placeholder="Select Country"
        class="w-12 md:w-3" [filter]="true"
        filterBy="name" 
        [showClear]="true">
        <ng-template pTemplate="selectedItem" let-selectedOption>
          <div class="flex align-items-center gap-2">
              <div> {{ selectedOption.name }} +{{ selectedOption.isdCode }}</div>
          </div>
      </ng-template>
      <ng-template let-country pTemplate="item">
          <div class="flex align-items-center gap-2">
              <div>{{ country.emoji  }} {{ country.name }}</div>
          </div>
      </ng-template>
      </p-dropdown>

      <div class="w-12 md:w-6">
        <input type="tel" pInputText formControlName="phoneNumber" placeholder="Enter Phone Number" class="w-full" />
      </div> -->

         <div class="formgrid grid col-9 md:col-12 m-0 w-full p-0 md:p-1 m-0 p-0 md:p-1">

            <div class="field col-12 md:col-6 pt-2">
               <label for="email" class="block mb-2">Email <i class="ml-1 text-red-500">*</i></label>
               <!-- Ensure label takes full width -->
               <p-iconField>
                  <p-inputIcon>
                     <i class="pi pi-envelope"></i>
                  </p-inputIcon>
                  <input pInputText formControlName="email" id="email" type="email" class="text-base text-color surface-overlay  border-1 border-solid surface-border border-round
                      appearance-none outline-none focus:border-primary w-full" [readonly]="isEmailReadonly" />
               </p-iconField>
               <div *ngIf="isControlInvalid('accountForm','email')" class="text-red-500">
                  {{ getErrorAccountMessage('email') }}
               </div>
               <div *ngIf="accountForm.get('isEmailVerified')?.value === false" class="text-red-500">
                  Email is not verified. Please verify your email address.
               </div>
            </div>
            <div class="w-12 md:w-3">
               <!-- {{ accountForm.get('socialLogin')?.value}} -->
               <!-- <button pButton label="Change Password" class="w-full" type="button" (click)="changePassword()"  
                  *ngIf="accountForm.get('socialLogin')?.value === false"></button> -->
               <label class="block mb-3">&nbsp;</label>

               <p-button  [text]="true" label="Change Password"  
                  (click)="changePassword()" *ngIf="accountForm.get('socialLogin')?.value === false" />

            </div>
            <!-- <div class="field col-12 md:col-6">
            </div> -->

            <!-- <div class="field col-12 md:col-6 pt-2">
               <label for="mobile" class="block mb-2">Mobile <i class="ml-1 text-red-500">*</i></label>
               <p-inputGroup>
                  <input pInputText id="mobile" formControlName="mobile" type="tel" class="text-base text-color surface-overlay  border-1 border-solid surface-border border-round
                      appearance-none outline-none focus:border-primary w-full" />
                  <button type="button" pButton icon="pi pi-check-square" class="p-button-warning" pTooltip="Get OTP"
                     tooltipPosition="bottom" showDelay="500" hideDelay="250"></button>
               </p-inputGroup>
               <div *ngIf="isControlInvalid('accountForm','mobile')" class="text-red-500">
                  {{ getErrorAccountMessage('mobile') }}
               </div>
            </div>
            <div class="field col-12 md:col-6">
            </div> -->

            <!-- <div class="field col-12 md:col-6 pt-2" [ngClass]="{'hidden': accountForm.get('socialLogin').value}">
               <label for="password">Password <i class="ml-1 text-red-500">*</i></label>
               <p-password formControlName="password"
                  styleClass="w-full text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary"
                  inputStyleClass="w-full" [toggleMask]="true">
               </p-password>
               <div *ngIf="isControlInvalid('accountForm','password')" class="text-red-500">
                  {{ getErrorAccountMessage('password') }}
               </div>
            </div>
            <div class="field col-12 md:col-6">
            </div>

            <div class="field col-12 md:col-6 pt-2" [ngClass]="{'hidden': accountForm.get('socialLogin').value}">
               <label for="confirmPassword">Confirm Password <i class="ml-1 text-red-500">*</i></label>
               <p-password formControlName="confirmPassword"
                  styleClass="w-full text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary"
                  inputStyleClass="w-full" [toggleMask]="true">
               </p-password>
               <div *ngIf="accountForm.errors && accountForm.errors['passwordsMismatch'] &&
          (accountForm.get('confirmPassword')?.touched || accountForm.get('password')?.touched)" class="text-red-500">
                  Passwords do not match.
               </div>
               <div *ngIf="isControlInvalid('accountForm','confirmPassword')" class="text-red-500">
                  {{ getErrorAccountMessage('confirmPassword') }}
               </div>
            </div>
            <div class="field col-12 md:col-6">
            </div> -->

         </div>

         <div class="formgrid grid col-9 md:col-12 m-0 w-full p-0 md:p-1">
            <!-- Mobile Label -->
            <div class="col-12">
               <label for="country" class="block mb-2">Mobile <i class="ml-1 text-red-500">*</i></label>
            </div>

            <!-- Country Dropdown -->
            <div class="field col-12 md:col-2">
               <p-dropdown [options]="countries" formControlName="isdCode" optionLabel="name" [filter]="true"
                  filterBy="name,isdCode" class="w-full"
                  [ngClass]="{'border-green-500': isValidPhone, 'border-gray-300': !isValidPhone}" placeholder="Country"
                  (onChange)="chek()">
                  <ng-template pTemplate="selectedItem" let-selectedOption>
                     <div class="flex align-items-center gap-2">
                        <div>
                           {{ selectedOption.isdCode.startsWith('+') ? selectedOption.isdCode : '+' +
                           selectedOption.isdCode }}
                        </div>
                     </div>
                  </ng-template>
                  <ng-template let-country pTemplate="item">
                     <div class="flex align-items-center gap-2">
                        <div>{{ country.emoji }} {{ country.name }}</div>
                     </div>
                  </ng-template>
               </p-dropdown>
               <div *ngIf="isControlInvalid('accountForm','isdCode')" class="text-red-500">
                  {{ getErrorAccountMessage('isdCode') }}
               </div>

            </div>

            <!-- Phone Number Input -->
            <div class="field col-12 md:col-4">
               <input type="tel" pInputText formControlName="mobile" placeholder="Enter mobile Number" class="w-full" />
               <div *ngIf="isControlInvalid('accountForm','mobile')" class="text-red-500">
                  {{ getErrorAccountMessage('mobile') }}
               </div>
               <div *ngIf="accountForm.get('isMobileVerified')?.value === false" class="text-red-500">
                  Mobile is not verified. Please verify your mobile number.
               </div>
            </div>
            <!-- <div class="w-12 md:w-3">
               <button pButton label="Send OTP" class="w-full" type="button" (click)="getOTP()"
                  [disabled]="!isValidPhone" *ngIf="accountForm.get('isMobileVerified')?.value === false"></button>
            </div>
            <div class="w-12 md:w-3">
               <button pButton label="Edit Mobile" class="w-full" type="button" (click)="editMobile()"
                  *ngIf="accountForm.get('isMobileVerified')?.value === true"></button>
            </div> -->
            <div class="w-12 md:w-3">
               <!-- <label class="block mb-4">&nbsp;</label> -->

               <p-button [text]="true" label="Send OTP" 
                  (click)="getOTP()" [disabled]="!isValidPhone"
                  *ngIf="accountForm.get('isMobileVerified')?.value === false" />

               <p-button [text]="true"  label="Edit Mobile" 
                  (click)="editMobile()" *ngIf="accountForm.get('isMobileVerified')?.value === true" />


            </div>

         </div>
         <input type="hidden" formControlName="isMobileVerified" class="hidden" />
         <input type="hidden" formControlName="isEmailVerified" class="hidden" />
         <input type="hidden" formControlName="socialLogin" class="hidden" />


      </form>
   </p-fieldset>

   <p-fieldset legend="Personal Details" class="col-12 md:col-9 p-0 md:p-1 m-0 p-0 md:p-1 pt-2 mt-2">
      <form [formGroup]="personalForm" (ngSubmit)="onSubmit()" class="p-fluid">

         <div class="formgrid grid col-9 md:col-12 m-0 w-full p-0 md:p-1 m-0 p-0 md:p-1  pt-2">
            <div class="field col-12 md:col-6 pt-2">
               <label for="firstName">First Name <i class="ml-1 text-red-500">*</i></label>
               <input pInputText id="firstName" type="text" formControlName="firstName"
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
               <div *ngIf="isPersonalFormControlInvalid('personalForm','firstName')" class="text-red-500">
                  {{ getErrorPersonalMessage('firstName') }}
               </div>
            </div>
            <div class="field col-12 md:col-6 pt-2">
               <label for="lastName">Lastname <i class="ml-1 text-red-500">*</i></label>
               <input pInputText id="lastName" type="text" formControlName="lastName"
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
               <div *ngIf="isPersonalFormControlInvalid('personalForm','lastName')" class="text-red-500">
                  {{ getErrorPersonalMessage('lastName') }}
               </div>
            </div>

            <div class="field col-12 md:col-6 pt-2">
               <label for="dateOfBirth">Date of Birth</label>
               <p-calendar formControlName="dateOfBirth"
                  styleClass="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full"
                  *ngIf="!showNativeCalendar" [showIcon]="true" [showOnFocus]="true" dateFormat="mm-dd-yy" />
               <div *ngIf="isPersonalFormControlInvalid('personalForm','dateOfBirth')" class="text-red-500">
                  {{ getErrorPersonalMessage('dateOfBirth') }}
               </div>
               <input type="date" formControlName="dateOfBirth" class="input text-base text-color surface-overlay p-3 surface-border
        border-round appearance-none outline-none focus:border-primary w-full md:hidden" *ngIf="showNativeCalendar" />


            </div>
            <div class="field col-12 md:col-6 pt-2">
               <label for="gender">Gender</label>

               <div class="flex flex-wrap gap-3">
                  <div class="flex align-items-center">
                     <div *ngFor="let gender of genderType" class="field-checkbox">
                        <p-radioButton [inputId]="gender" [value]="gender" formControlName="gender"
                           class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary " />
                        <label [for]="gender" class="ml-2 mr-2 gap-2">
                           {{ gender }}
                        </label>
                     </div>
                  </div>
                  <div *ngIf="isPersonalFormControlInvalid('personalForm','password')" class="text-red-500">
                     {{ getErrorPersonalMessage('gender') }}
                  </div>
               </div>
            </div>

            <app-location-selection #locationSelectionComponent (resetFormRequest)="onReset()"
               formControlName="location" (locationFormStatus)="onLocationFormStatus($event)"
               [locationFormValidators]="locationFormValidators" [existingLocationData]="existingLocationData"
               [isAddress2Visible]="true" [isAddress2Optional]="true" [isStateOptional]="true" [isCityOptional]="true"
               [isZipOptional]="true" (countriesLoaded)="onCountriesLoaded()" (citiesLoaded)="onCitiesLoaded()"
               (statesLoaded)="onStatesLoaded()"></app-location-selection>

            <!-- <div class="field col-12">
          <label for="address">Address 1 <i class="ml-1 text-red-500">*</i></label>
          <input pInputText id="address" type="text" formControlName="address1"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          <div *ngIf="isPersonalFormControlInvalid('personalForm','address1')" class="text-red-500">
            {{ getErrorPersonalMessage('address1') }}
          </div>
        </div>
        <div class="field col-12">
          <label for="address">Address 2</label>
          <input pInputText id="lastname6" type="text" formControlName="address2"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          <div *ngIf="isPersonalFormControlInvalid('personalForm','address2')" class="text-red-500">
            {{ getErrorPersonalMessage('address2') }}
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <label for="country">Country <i class="ml-1 text-red-500">*</i></label>
          <p-dropdown formControlName="country" [options]="dummy" optionLabel="name" placeholder="Select a Country"
            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full" />

          <div *ngIf="isPersonalFormControlInvalid('personalForm','country')" class="text-red-500">
            {{ getErrorPersonalMessage('country') }}
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <label for="state">State</label>
          <p-dropdown formControlName="state" [options]="dummy" optionLabel="name" placeholder="Select a State"
            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full" />
          
          <div *ngIf="isPersonalFormControlInvalid('personalForm','state')" class="text-red-500">
            {{ getErrorPersonalMessage('state') }}
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <label for="state">City</label>
          <p-dropdown formControlName="city" [options]="dummy" optionLabel="name" placeholder="Select a City"
            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full" />
          <div *ngIf="isPersonalFormControlInvalid('personalForm','city')" class="text-red-500">
            {{ getErrorPersonalMessage('city') }}
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <label for="zip">Zip <i class="ml-1 text-red-500">*</i></label>
          <input pInputText id="zip" type="number" formControlName="zip"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          <div *ngIf="isPersonalFormControlInvalid('personalForm','zip')" class="text-red-500">
            {{ getErrorPersonalMessage('zip') }}
          </div>
        </div> -->

         </div>
      </form>


      <ng-container *ngIf="!isEditForm; else insertForm">
         <div class="flex pt-4 justify-content-end">
            <p-button label="Next" icon="pi pi-arrow-right" iconPos="right" (onClick)="onSubmit()" />
         </div>
      </ng-container>

      <ng-template #insertForm>
         <div class="flex pt-4 justify-content-between">
            <!-- <p-button label="Previous" icon="pi pi-arrow-left" iconPos="left" (onClick)="onSubmit('previous')" /> -->
            <p-button label="Update" icon="pi pi-save" iconPos="right" (onClick)="onSubmit()" />
         </div>
      </ng-template>

   </p-fieldset>

</div>



<p-dialog [(visible)]="showOTPDialog" [modal]="true" [style]="{ width: '25rem' }" closeOnEscape="true" closable="false"
   draggable="false" resizable="false">
   <!-- Message Header -->
   <div class="mb-2">
      <span class="p-text-secondary block text-sm line-height-2">
         OTP has been sent to <strong>+{{ this.accountForm?.get('isdCode')?.value?.isdCode ?? '' }} {{
            this.accountForm?.get('mobile')?.value ?? '' }}</strong>.
         Please enter the OTP below to verify your phone number.
      </span>
      <span *ngIf="isTimerActive; else resendLink">
         Resend OTP in <strong>{{ countdown }} seconds</strong>.
      </span>
      <ng-template #resendLink>
         <p-button label="Resend" [link]="true" (click)="resendOTP()" />
      </ng-template>
      <!-- <p-button  label="Change Number"  [link]="true"  (click)="editPhoneNumber()" class="block mt-2 cursor-pointer text-primary"  /> -->
   </div>

   <!-- OTP Input -->
   <div class="flex justify-content-center align-items-center h-full w-full">
      <div class="flex justify-center items-center gap-2 mb-1">
         <p-inputOtp id="otp" [(ngModel)]="otp" [integerOnly]="true"></p-inputOtp>
      </div>
   </div>



   <!-- Footer Actions -->
   <div class="flex justify-content-end gap-2 mt-2">
      <p-button label="Edit Mobile" [text]="true" severity="secondary" (click)="showOTPDialog = false" />
      <p-button label="Confirm" [outlined]="true" severity="secondary" (click)="verifyOTP()" />
   </div>

</p-dialog>


<!-- <p-dialog header="Change Password" [(visible)]="displayDialog" [modal]="true" [closable]="false"
   [style]="{ width: '30vw' }">

   <p-messages [(value)]="passwordChangeMessages" [enableService]="false" [closable]="false" />
   <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePasswordSubmit()">
       
      <div class="p-field">
         <label for="oldPassword">Old Password</label>
         <input id="oldPassword" type="password" pInputText formControlName="oldPassword"
            placeholder="Enter old password" />
         <small *ngIf="changePasswordForm.get('oldPassword').touched && changePasswordForm.get('oldPassword').invalid"
            class="p-error">
            Old Password is required.
         </small>
      </div>

     
      <div class="p-field">
         <label for="newPassword">New Password</label>
         <input id="newPassword" type="password" pInputText formControlName="newPassword"
            placeholder="Enter new password" />
         <small *ngIf="changePasswordForm.get('newPassword').touched && changePasswordForm.get('newPassword').invalid"
            class="p-error">
            Password must be at least 6 characters long.
         </small>
      </div>

     
      <div class="p-field">
         <label for="confirmPassword">Confirm Password</label>
         <input id="confirmPassword" type="password" pInputText formControlName="confirmPassword"
            placeholder="Re-enter new password" />
         <small
            *ngIf="changePasswordForm.get('confirmPassword').touched && changePasswordForm.get('confirmPassword').invalid"
            class="p-error">
            Confirm Password is required.
         </small>
         <small *ngIf="passwordsMismatch" class="p-error">Passwords do not match.</small>
      </div>

     
      <div class="p-mt-3">
         <button type="button" pButton label="Cancel" icon="pi pi-times" class="p-button-text"
            (onClick)="displayDialog = false"></button>
         <button type="submit" pButton label="Change Password" icon="pi pi-check"
            [disabled]="changePasswordForm.invalid || passwordsMismatch"></button>
      </div>
   </form>
</p-dialog> -->

<p-dialog header="Change Password" [(visible)]="displayDialog" [modal]="true" [closable]="false"
   [style]="{ width: '30vw' }">
   <p-messages [(value)]="passwordChangeMessages" [enableService]="false" [closable]="false" class="mb-3"></p-messages>
   <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePasswordSubmit()">
      <div class="grid gap-3">
         <!-- Old Password -->
         <div class="col-12">
            <label for="oldPassword" class="block mb-2">Old Password</label>
            <!-- <input id="oldPassword" type="password" pInputText formControlName="oldPassword"
               placeholder="Enter old password" class="w-full" /> -->
               <p-password  id="oldPassword"  type="password" class="w-full"  placeholder="Old Password"
               styleClass="p-password p-component p-inputwrapper p-input-icon-right" [style]="{'width':'100%'}"
               [inputStyle]="{'width':'100%'}" [toggleMask]="true"   formControlName="oldPassword" [feedback]="false"
               ></p-password>
            <small *ngIf="changePasswordForm.get('oldPassword').touched && changePasswordForm.get('oldPassword').invalid"
               class="p-error">
               Old Password is required.
            </small>
         </div>

         <!-- New Password -->
         <div class="col-12">
            <label for="newPassword" class="block mb-2">New Password</label>
            <!-- <input id="newPassword" type="password" pInputText formControlName="newPassword"
               placeholder="Enter new password" class="w-full" /> -->
               <p-password  id="newPassword"  type="password" class="w-full"  placeholder="New Password"
               styleClass="p-password p-component p-inputwrapper p-input-icon-right" [style]="{'width':'100%'}"
               [inputStyle]="{'width':'100%'}" [toggleMask]="true"   formControlName="newPassword"
               promptLabel="Choose a password"
               weakLabel="Too simple" 
               mediumLabel="Average complexity" 
               strongLabel="Complex password" ></p-password>
            <small *ngIf="changePasswordForm.get('newPassword').touched && changePasswordForm.get('newPassword').invalid"
               class="p-error">
               Password must be at least 6 characters long.
            </small>
         </div>

         <!-- Confirm Password -->
         <div class="col-12">
            <label for="confirmPassword" class="block mb-2">Confirm Password</label>
            <!-- <input id="confirmPassword" type="password" pInputText formControlName="confirmPassword"
               placeholder="Re-enter new password" class="w-full" /> -->
               <p-password id="confirmPassword" type="password" class="w-full"  placeholder="Confirm Password"
               styleClass="p-password p-component p-inputwrapper p-input-icon-right" [style]="{'width':'100%'}"
               [inputStyle]="{'width':'100%'}" [toggleMask]="true"   formControlName="confirmPassword"
               promptLabel="Choose a password"
               weakLabel="Too simple" 
               mediumLabel="Average complexity" 
               strongLabel="Complex password" ></p-password>
               <!-- <p-password [feedback]="false" 
               id="confirmPassword" 
               formControlName="confirmPassword"
               placeholder="Re-enter new password" 
               [toggleMask]="true"  
               promptLabel="Choose a password"
               weakLabel="Too simple" 
               mediumLabel="Average complexity" 
               strongLabel="Complex password"  /> -->

            <small
               *ngIf="changePasswordForm.get('confirmPassword').touched && changePasswordForm.get('confirmPassword').invalid"
               class="p-error">
               Confirm Password is required.
            </small>
            <small *ngIf="passwordsMismatch" class="p-error">Passwords do not match.</small>
         </div>

         <!-- Actions -->
         <div class="col-12 flex justify-content-end gap-2 p-mt-3">
            <button type="button" pButton label="Cancel" icon="pi pi-times" class="p-button-text"
               (click)="displayDialog = false"></button>
            <button type="submit" pButton label="Change Password" icon="pi pi-check"
               [disabled]="changePasswordForm.invalid || passwordsMismatch"></button>
         </div>
      </div>
   </form>
</p-dialog>
