<form [formGroup]="subscriptionForm">
  <!-- Currency Dropdown -->

  <input type="hidden" formControlName="subscriptionPackage">

  <div *ngIf="isControlInvalid('subscriptionForm','currency')" class="text-red-500">
    Currency is required
  </div>

  <div *ngIf="subscriptionForm.get('subscriptionPackage')?.hasError('required') && isSubmitted" class="text-red-500">
    Select Subscription package.
  </div>


  <div *ngIf="isControlInvalid('subscriptionForm','tenure')" class="text-red-500">
    Tenure is required
  </div>


  <div class="flex pt-4 justify-content-end mb-3">
    <p-dropdown [options]="currencies" formControlName="currency" optionLabel="name" [showClear]="false"
      placeholder="Currency" (onChange)="onCurrencyChange($event)">
      <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2" *ngIf="f['currency'].value">
          <div>{{ f['currency'].value?.name }}</div>
        </div>
      </ng-template>
      <ng-template let-currency pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>{{ currency?.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>

  <!-- Tenure SelectButton (Monthly/Yearly) -->
  <div class="formgrid grid md:justify-content-center md:gap-5"
    *ngIf="subscriptionPackages && subscriptionPackages.length>0">
    <div class="field col-12 md:12 gap-3">
      <div class="card flex justify-content-center">
        <p-selectButton [options]="tenureOptions" formControlName="tenure" optionLabel="label" optionValue="value"
          (onChange)="onTenureChange($event)" [unselectable]="false" [allowEmpty]="false">
        </p-selectButton>
      </div>
    </div>

    <!-- Loop through Subscription Packages -->
    <div *ngFor="let package of subscriptionPackages; let i = index" class="field col-12 md:col-3"
      [ngClass]="{'border-solid border-primary-500': selectedPackageIndex === i, 'border-transparent': selectedPackageIndex !== i}">
      <p-card [header]="package.name" [subheader]="package.subheader" class="col-3 sm:mr-4"
        (click)="onSelectPackage(i)">
        <ng-template pTemplate="header">
          <img alt="Card" src="https://primefaces.org/cdn/primeng/images/card-ng.jpg" />
        </ng-template>

        <ul class="list-none p-0 m-0">
          <!-- Dynamically Display Price based on selected currency and tenure -->
          <li class="text-xl p-2">
            <span *ngIf="f['currency']?.value?.name === 'INR'">
              <span *ngIf="(f['tenure']?.value === 'MONTHLY')">
                ₹ {{ package?.INR_MONTHLY?.offerPrice?? 0 }} <del *ngIf="package?.INR_MONTHLY?.price">₹{{
                  package?.INR_MONTHLY?.price }}</del> <i *ngIf="!package.isTrial"> / month</i>
              </span>
              <span *ngIf="f['tenure']?.value === 'YEARLY'">
                ₹ {{ package?.INR_YEARLY?.offerPrice??0 }} <del *ngIf="package?.INR_MONTHLY?.price">₹{{
                  package?.INR_YEARLY?.price }}</del><i *ngIf="!package.isTrial"> / year</i>
                <span class="text-green-500 font-bold pl-2">{{package.INR_YEARLY?.savingsHeader??''}}</span>
              </span>
            </span>

            <span *ngIf="f['currency']?.value?.name === 'USD'">
              <span *ngIf="f['tenure']?.value === 'MONTHLY'">
                ${{ package?.USD_MONTHLY?.offerPrice??0 }} <del *ngIf="package?.INR_MONTHLY?.price">${{
                  package?.USD_MONTHLY?.price??0 }}</del> <i *ngIf="!package.isTrial"> / month</i>
              </span>
              <span *ngIf="f['tenure']?.value === 'YEARLY'">
                ${{ package.USD_YEARLY?.offerPrice??0 }} <del *ngIf="package?.INR_MONTHLY?.price">
                  ${{ package?.INR_MONTHLY?.price }}
                </del> <i *ngIf="!package.isTrial"> / year</i>
                <span class="text-green-500 font-bold pl-2">{{package.INR_YEARLY?.savingsHeader??''}}</span>
              </span>
            </span>

          </li>

          <!-- Display Features -->
          <li *ngFor="let feature of package.features"
            class="flex align-items-center gap-2 sm:text-xl sm:p-4 md:text-xl md:p-3">
            <i *ngIf="feature.available" class="pi pi-check text-green-500 sm:text-xl md:text-xl"></i>
            <i *ngIf="!feature.available" class="pi pi-times text-red-500 sm:text-xl md:text-xl"></i>
            <span>{{ feature.feature }}</span>
          </li>

          <!-- Show Trial Duration if available -->
          <!-- <li *ngIf="package.duration" class="text-lg p-2 text-yellow-500">
            Trial Duration: {{ package.duration }}
          </li> -->
        </ul>

        <ng-template pTemplate="footer">
          <button pButton type="button" label="Choose {{ package.name }}" class="p-button-success"></button>
        </ng-template>
      </p-card>
    </div>
  </div>

</form>
<ng-container *ngIf="isEditForm; else insertForm">
  <div class="flex pt-4 justify-content-end">
    <p-button 
      type="button" 
      label="Next" 
      icon="pi pi-arrow-right" 
      iconPos="right" 
      (onClick)="onSubmit('next_EditForm')">
    </p-button>
  </div>
</ng-container>

<ng-template #insertForm>
  <div class="flex pt-4 justify-content-between">
    <p-button type="button" label="Previous" icon="pi pi-arrow-left" iconPos="left" (onClick)="onSubmit('previous')" />
    <p-button type="button" label="Next" icon="pi pi-arrow-right" iconPos="right"
      (onClick)="onSubmit('next')"></p-button>
  </div>
</ng-template>

<div class="flex pt-0 justify-content-end mb-5">
  <p *ngIf="subscriptionForm.get('subscriptionPackage')?.hasError('required') && isSubmitted" class="text-red-500">
    Select Subscription Package.
  </p>
</div>