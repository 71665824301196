import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { UserRegistration } from '../model/user-registration';
import { AppRoutePathConstants } from 'src/app/commons/app-route-path-constants';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class UserRegistrationService {

  private apiUrl = AppConstants.API_URL + "api/v1/userRegistrations/";
  private apiUrlAccount = AppConstants.API_URL + "api/v1/register/account-details";
  private apiUrlCompany = AppConstants.API_URL + "api/v1/register/company-details";



  public userLoaded: EventEmitter<UserRegistration> = new EventEmitter();


  constructor(private http: HttpClient) { }

  getAll(): Observable<UserRegistration[]> {
    return this.http.get<UserRegistration[]>(this.apiUrl);
  }

  get(id: number): Observable<UserRegistration> {
    return this.http.get<UserRegistration>(`${this.apiUrl}/${id}`);
  }

  /* save(userRegistration: UserRegistration): Observable<UserRegistration> {
    console.log(`Save Url ${this.apiUrl}`);
    return this.http.post<UserRegistration>(this.apiUrl, userRegistration);
  } */

  save(userRegistration: UserRegistration, isUserInvited = false, invitationToken: string | null): Observable<UserRegistration> {
    console.log(`Save Url ${this.apiUrl}`);

    let params = new HttpParams();
    // Add any query parameters you need
    if (isUserInvited) {
      params = params.append('isUserInvited', 'value1');
    }
    if (invitationToken) {
      params = params.append('invitationToken', invitationToken);
    }

    return this.http.post<UserRegistration>(this.apiUrl, userRegistration, { params });
  }

  update(id: number, userRegistration: UserRegistration): Observable<UserRegistration> {
    return this.http.put<UserRegistration>(`${this.apiUrl}/${id}`, userRegistration);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}api/v1/${id}`);
  }


  /* getUserRegistrationByUserId(): Observable<UserRegistration> {
    const url = this.apiUrl + "userId";
    console.log(`Url is ${url}`);
    return this.http.get<UserRegistration>(`${url}`);
  } */
  getUserRegistrationByUserId(): Observable<UserRegistration> {
    const url = this.apiUrl + "userId";
    return this.http.get<UserRegistration>(url).pipe(
      tap(data => this.userLoaded.emit(data)) // Use tap operator to trigger the event when the data is loaded
    );
  }

  getAllCountries(): Observable<any> {
    const url = AppConstants.API_URL + "api/v1/countries";
    return this.http.get<any>(`${url}`);
  }

  getAllISDCodes(): Observable<any> {
    const url = AppConstants.API_URL + "api/v1/countries/iso-codes";
    return this.http.get<any>(`${url}`);
  }

  sendOTP(phoneNumber: string, isdCode?: string): Observable<any> {
    let url = AppConstants.API_URL + "api/v1/otp-generate?phoneNumber=" + phoneNumber;
    if (isdCode) {
      url += `&isdCode=${isdCode}`;
    }
    console.log(`Save Url ${url}`);
    return this.http.post<any>(url, null);
  }

  validateOTP(phoneNumber: string, otp: number, isdCode?: number) {
    let url = AppConstants.API_URL + 
            `api/v1/otp-validate?phoneNumber=${phoneNumber}&otp=${otp}`;
  
  // Append isdCode only if it exists
  if (isdCode) {
    url += `&isdCode=${isdCode}`;
  }
    console.log(`Save Url ${url}`);
    return this.http.post<any>(url, null);
  }

  saveUserAccountDetails(userCombinedDetails: any): Observable<any> {
    console.log(`Save Url ${this.apiUrlAccount}`);

    return this.http.post<UserRegistration>(this.apiUrlAccount, userCombinedDetails, { withCredentials: true });
  } ///api/v1

  saveCompanyDetails(data: any): Observable<any> {
    console.log(`Save apiUrlCompany Url ${this.apiUrlCompany}`);
    return this.http.post<UserRegistration>(this.apiUrlCompany, data, { withCredentials: true });
  } ///api/v1

  getAccountDetails(): Observable<any> {
    const url = AppConstants.API_URL + "api/v1/account-details";
    return this.http.get<any>(`${url}`);
  }

  getCompanyDetails(): Observable<any> {
    const url = AppConstants.API_URL + "api/v1/company-details";
    return this.http.get<any>(`${url}`);
  } 

 
  updateCompanyDetails(data: any): Observable<any> {
     const url = AppConstants.API_URL +  'api/v1/update/company-details'
    console.log(`updateCompanyDetails Url ${url}`);
    return this.http.put<UserRegistration>(url, data, { withCredentials: true });
  } 

  changePasswordService({ oldPassword, newPassword }): Observable<any> {
    const url = AppRoutePathConstants.PATH_SERVER_URL + "api/v1/password/change-password";
    return this.http.post(url, { oldPassword, newPassword }) ;
   
  }
}
