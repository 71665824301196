<!-- email-sent-notification.component.html -->
<div class="p-grid p-justify-center p-align-center p-mt-5">
    <div class="p-card p-shadow-5 w-fluid" >
      <div class="p-card-header p-text-center">
        <i class="pi pi-envelope p-text-primary p-mb-3" style="font-size: 3rem;"></i>
        <h2>Email Sent</h2>
      </div>
      <div class="p-card-body p-text-center">
        <p>
          An email with a verification link has been sent to your registered email address. Please click the link to verify your email.
        </p>
        <p><strong>The link is valid for 30 minutes only.</strong></p>
        <p>
          Once your email is verified, refresh the page or log in again to continue.
        </p>
      </div>
      <div class="p-card-footer p-text-center">
        <button pButton label="Refresh Page" class="p-button-rounded p-button-outlined p-mr-2" (click)="refreshPage()"></button>
        <button pButton label="Login" class="p-button-rounded p-button-success" routerLink="/login"></button>
      </div>
    </div>
  </div>
  