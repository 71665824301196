<div class="p-fluid p-card p-p-3 p-mt-4 p-shadow-2">
    <h3 class="p-text-center">{{ isResetMode ? 'Reset Password' : 'Forgot Password' }}</h3>

    <form [formGroup]="forgotPasswordForm" *ngIf="!isResetMode" (ngSubmit)="sendResetLink()">
        <div class="p-field">
            <label for="email">Email</label>
            <input id="email" type="email" pInputText formControlName="email" placeholder="Enter your email" />
            <small *ngIf="forgotPasswordForm.get('email').touched && forgotPasswordForm.get('email').invalid"
                class="p-error">
                Valid email is required.
            </small>
        </div>

        <div class="p-mt-3">
            <button pButton label="Send Reset Link" type="submit" class="p-button-primary"
                [disabled]="forgotPasswordForm.invalid"></button>
        </div>
    </form>

    <form [formGroup]="resetPasswordForm" *ngIf="isResetMode" (ngSubmit)="resetPassword()">
        <!-- <div class="p-field">
            <label for="token">Reset Token</label>
            <input id="token" type="text" pInputText formControlName="token" placeholder="Enter reset token" />
            <small *ngIf="resetPasswordForm.get('token').touched && resetPasswordForm.get('token').invalid"
                class="p-error">
                Token is required.
            </small>
        </div> -->
        <input type="hidden" formControlName="token" />
        

        <!-- New Password -->
        <div class="p-field">
            <label for="newPassword">New Password</label>
            <input id="newPassword" type="password" pInputText formControlName="newPassword"
                placeholder="Enter new password" />
            <small *ngIf="resetPasswordForm.get('newPassword').touched && resetPasswordForm.get('newPassword').invalid"
                class="p-error">
                Password must be at least 6 characters long.
            </small>
        </div>

        <!-- Confirm Password -->
        <div class="p-field">
            <label for="confirmPassword">Confirm Password</label>
            <input id="confirmPassword" type="password" pInputText formControlName="confirmPassword"
                placeholder="Re-enter new password" />
            <small
                *ngIf="resetPasswordForm.get('confirmPassword').touched && resetPasswordForm.get('confirmPassword').invalid"
                class="p-error">
                Confirm Password is required.
            </small>
            <small *ngIf="passwordMismatch" class="p-error">Passwords do not match.</small>
        </div>

        <div class="p-mt-3">
            <button pButton label="Reset Password" type="submit" class="p-button-success"
                [disabled]="resetPasswordForm.invalid"></button>
        </div>
    </form>

    <div class="p-mt-3 p-text-center">
        <p-button [label]="isResetMode ? 'Back to Forgot Password' : 'Switch to Reset Password'" [link]="true"
            (click)="isResetMode = !isResetMode" />
        <!-- <a href="#" (click)="isResetMode = !isResetMode">{{ isResetMode ? 'Back to Forgot Password' : 'Switch to Reset Password' }}</a> -->
    </div>
</div>