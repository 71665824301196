import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, debounceTime, filter, Observable, of, switchMap } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { CurrentUser } from 'src/app/interfaces/current-user';
import { AuthService } from 'src/app/services/auth.service';
import { SecretKeyService } from 'src/app/services/secret-key.service';
import { UserService } from 'src/app/services/user.service';
import * as platform from 'platform';
import { DeviceService } from 'src/app/helper/device-service';
import { MessageService } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { AppComponent } from 'src/app/app.component';
import { ClientJS } from 'clientjs';
import { SHARED_MODULES } from '../../shared-imports';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Message } from 'primeng/api';
import { AppRoutePathConstants } from 'src/app/commons/app-route-path-constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [ToastMessagesService, MessageService],
  standalone: true,
  imports: [SHARED_MODULES]
})
export class LoginComponent implements OnInit {
  register: boolean = false;
  flipped = false;
  returnUrl!: string;
  formHeader!: string;
  formSubHeader!: string;
  visible = false;
  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  currentUser!: CurrentUser | null;
  googleURL = AppConstants.GOOGLE_AUTH_URL;
  linkedinURL = AppConstants.LINKEDIN_AUTH_URL;
  microsoftURL = AppConstants.MICROSOFT_AUTH_URL;
  error!: string;
  facebookUrl = AppConstants.API_URL + `/oauth2/authorization/facebook?deviceId=`;
  googleUrl = AppConstants.API_URL + `/oauth2/authorization/google?deviceId=`;
  microsoftUrl = AppConstants.API_URL + `/oauth2/authorization/google?deviceId=`;
  linkedInUrl = AppConstants.API_URL + `/oauth2/authorization/google?deviceId=`;

  formGroup: FormGroup | undefined;
  signInForm!: FormGroup;
  signUpForm!: FormGroup;

  valCheck: string[] = ['remember'];
  emailExists: boolean = false;
  messages: Message[] | undefined;
  hasInvalidCredentials = false;
  fingerprint: String | null;

  password!: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private deviceService: DeviceService,
    private toastMessageService: ToastMessagesService,
    private titleService: Title,
    private appComponent: AppComponent,
    private fb: FormBuilder,
  ) {
    this.setPageTitle();
    const client = new ClientJS();
    this.fingerprint = client.getFingerprint();
    this.initCustomSignIn();
    this.initCustomSignUp();
    // Print the 32bit hash id to the console
    console.log(this.fingerprint);
    this.facebookUrl = `${AppConstants.API_URL}oauth2/authorization/facebook?deviceId=${this.fingerprint}`;
    this.googleUrl = `${AppConstants.API_URL}oauth2/authorization/google?deviceId=${this.fingerprint}&isInvited=true&inviteToken=12314ABCD`;
    this.microsoftUrl = `${AppConstants.API_URL}oauth2/authorization/microsoft?deviceId=${this.fingerprint}`;
    this.linkedInUrl = `${AppConstants.API_URL}oauth2/authorization/linkedin?deviceId=${this.fingerprint}`;


  }

  ngOnInit(): void {
    this.messages = [{ severity: 'error', detail: 'Invalid username or password' }];

    this.setupEmailValidation();
    this.toastMessageService.clearMessage();
    let invitationToken;
    let isUserInvited;
    this.route.queryParams.subscribe((params) => {
      invitationToken = params['invitationToken'];
      isUserInvited = params['isUserInvited'];

      let message =
        'Invitation Token: ' +
        (invitationToken ? invitationToken : 'Not provided');
      message +=
        '\nIs User Invited: ' +
        (isUserInvited ? isUserInvited : 'Not provided');

    });
    this.register = this.route.snapshot.queryParams['register'] === 'true';
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    console.log(`Return Url is ${this.returnUrl}`);
    console.log(`Return Url is ${this.returnUrl}`);
    const code: string = this.route.snapshot.queryParamMap.get('code')!;
    const token: string = this.route.snapshot.queryParamMap.get('token')!;
    const error: string = this.route.snapshot.queryParamMap.get('error')!;
    const forceLogin: string =
      this.route.snapshot.queryParamMap.get('forceLogin')!;
    console.log(`error %%%%% ${error}`);

    const sessionExpired: string =
      this.route.snapshot.queryParamMap.get('sessionExpired')!;
    const forbiddenAccess: string =
      this.route.snapshot.queryParamMap.get('forbiddenAccess')!;
    if (error) {
      this.visible = true;
      this.error = error;
    } else if (forbiddenAccess) {
      alert('You are forbidden from Login.component.ts');
    } else if (sessionExpired) {
      window.localStorage.clear();
    } else {
      /* else if (this.returnUrl) {
      this.router.navigateByUrl(this.returnUrl);
    } */
      //  const redirectUrl = localStorage.getItem(AppConstants.REDIRECT_SNAPSHOT_URL) ? localStorage.getItem(AppConstants.REDIRECT_SNAPSHOT_URL) : '/sellers';
      // localStorage.removeItem('redirectUrl'); // Clear the saved URL
      //console.log(`redirecturl is from login ${redirectUrl}`);
      //if(redirectUrl){
      //this.router.navigate([redirectUrl]);
      //}

      //console.log(`redirectUrl from login component is ${redirectUrl}`);
      const redirectUrl = localStorage.getItem(
        AppConstants.REDIRECT_SNAPSHOT_URL
      );
      // console.log(`redirect url is ${redirectUrl}`);
      if (redirectUrl) {
        console.log(`redirect url is ${redirectUrl}`);
        if (invitationToken && isUserInvited) {
          //    this.router.navigateByUrl(redirectUrl+"?invitationToken="+invitationToken+"&isUserInvited=true");
        }

        // this.router.navigateByUrl(redirectUrl);
        localStorage.removeItem(AppConstants.REDIRECT_SNAPSHOT_URL);
      } else {
        if (invitationToken && isUserInvited) {
          // this.router.navigateByUrl(AppConstants.DASHBOARD+"?invitationToken="+invitationToken+"&isUserInvited=true");
        } else {
          //  this.router.navigateByUrl(AppConstants.DASHBOARD);
        }
      }
    }
  }

  onSubmit(): void {
    console.log(`OnSUbmit Login.tml`);
    this.authService.login(this.form).subscribe(
      (data) => {
        //   this.tokenStorage.saveToken(data.accessToken);
        //  this.login(data.user);
      },
      (err) => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  /*  startGoogleLogin(info: any): Observable<any> {
    console.log(`startGoogleLogin`);
    const url = `${AppConstants.API_URL}openforall/google-login`;
    return this.httpClient.post(url, info);
  }

  async onGoogleLogin() {
    this.deviceService.getDeviceIdObservable().subscribe(
      (deviceInfo: any) => {
        console.log(`deviceInfo ${JSON.stringify(deviceInfo)}`);
        const platformInfo = platform.parse(navigator.userAgent);
        let info = {
          browser: platformInfo.name + ' ' + platformInfo.version,
          os: platformInfo?.os?.family + ' ' + platformInfo?.os?.version,
          deviceId: deviceInfo,
        };
        console.log(`INFO IS ${JSON.stringify(info)}`);
        this.startGoogleLogin(info).subscribe((response) => {
          console.log(`Response is ${JSON.stringify(response)}`);
          if (response.message) {
            console.log(`Response Url is ${response.message}`);
            window.location.href = response.message;
          }
        });
      },
      (error: any) => {
        console.error(`deviceInfo error ${JSON.stringify(error)}`);
      },
      () => {}
    );
  }

  onMicrosoftLogin() {}

  onLinkedInLogin() {} */

  startSocialLogin(url: string, info: any): Observable<any> {
    console.log(`startSocialLogin`);
    return this.httpClient.post(url, info);
  }

  async onSocialLogin(apiEndpoint: string) {
    this.deviceService.getDeviceIdObservable().subscribe(
      (deviceInfo: any) => {
        console.log(`deviceInfo ${JSON.stringify(deviceInfo)}`);
        const platformInfo = platform.parse(navigator.userAgent);
        let info = {
          browser: platformInfo.name + ' ' + platformInfo.version,
          os: platformInfo?.os?.family + ' ' + platformInfo?.os?.version,
          deviceId: deviceInfo,
        };
        console.log(`INFO IS ${JSON.stringify(info)}`);
        this.startSocialLogin(apiEndpoint, info).subscribe(
          (response) => {
            console.log(`Response is ${JSON.stringify(response)}`);
            if (response.message) {
              console.log(`Response Url is ${response.message}`);
              window.location.href = response.message;
            }
          },
          (error: any) => {
            console.error(`deviceInfo error ${JSON.stringify(error)}`);
            this.toastMessageService.showErrorMessage(
              'Login Error',
              'An error occurred while attempting to log in.'
            );
            // this.toastr.error("An error occurred while attempting to log in."); // Display error toast
          },
          () => { }
        );
      },
      (error: any) => {
        console.error(`deviceInfo error ${JSON.stringify(error)}`);
        this.toastMessageService.showErrorMessage(
          'Device Information Error',
          'An error occurred while getting device information.'
        );
        // this.toastr.error("An error occurred while getting device information."); // Display error toast
      },
      () => { }
    );
  }

  private initCustomSignIn() {
    this.signInForm = this.fb.group({
      deviceId: [this.fingerprint],
      email: [
        null, // Default value
        [Validators.required,Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/)] // Validators: Required and must be a valid email
      ],
      password: [
        null, // Default value
        [Validators.required] // Validators: Required
      ]
    });
  }


  private initCustomSignUp() {
    this.signUpForm = this.fb.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required,Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/)]], // Ensure the name matches
      password: [null, [Validators.required]]
    });
    
  }

  // private setupEmailValidation() {
  //   this.signUpForm.get('email')?.valueChanges
  //     .pipe(
  //       debounceTime(300), // Wait 300ms before making the API call
  //       switchMap((email: string) =>
  //         this.authService.checkEmailExists(email).pipe(
  //           catchError(() => of(false)) // Handle errors gracefully
  //         )
  //       )
  //     )
  //     .subscribe((exists: boolean) => {
  //       this.emailExists = exists;
  //       if (exists) {
  //         this.signUpForm.get('email')?.setErrors({ emailExists: true });
  //       } else {
  //         this.signUpForm.get('email')?.setErrors(null);
  //       }
  //     });
  // }

  private setupEmailValidation() {
    this.signUpForm.get('email')?.valueChanges
      .pipe(
        debounceTime(300), // Wait 300ms before making the API call
        filter(() => this.signUpForm.get('email')?.valid), // Skip check if invalid
        switchMap((email: string) =>
          this.authService.checkEmailExists(email).pipe(
            catchError(() => of(false)) // Handle errors gracefully
          )
        )
      )
      .subscribe((exists: boolean) => {
        const emailControl = this.signUpForm.get('email');
        if (emailControl) {
          const existingErrors = emailControl.errors || {}; // Get existing errors
  
          if (exists) {
            // Add the 'emailExists' error
            this.emailExists = true;
            emailControl.setErrors({ ...existingErrors, ['emailExists']: true });
          } else {
            // Remove only the 'emailExists' error
            delete existingErrors['emailExists'];
            this.emailExists = false;
            if (Object.keys(existingErrors).length === 0) {
              emailControl.setErrors(null); // If no errors remain, set null
            } else {
              emailControl.setErrors(existingErrors); // Retain other errors
            }
          }
        }
      });
  }
  
  

  // onCustomSignIn() {
  //   if (this.signInForm.valid) {
  //     this.authService.customLogin(this.signInForm.value).subscribe(response => {
  //       console.log('LOGIN successful', response); // Handle success response
  //       if (response.status === 'PERMANENT_REDIRECT' && response.redirectUrl) {
  //         const redirectUrl = decodeURIComponent(response.redirectUrl);
  //         console.log('LOGIN successful redirectUrl decodeURIComponent ', redirectUrl); 
  
  //         // Extract the base path and query parameters
  //         const [path, queryParamsString] = redirectUrl.split('?');
  //         const queryParams = queryParamsString
  //           ? queryParamsString.split('&').reduce((acc, param) => {
  //               const [key, value] = param.split('=');
  //               acc[key] = decodeURIComponent(value);
  //               return acc;
  //             }, {} as Record<string, string>)
  //           : {};
  
  //         // Navigate to the route with query parameters
  //         this.router.navigate([path], { queryParams });
  //       } else {
  //         console.log('Response:', response);
  //       }
  //     }, error => {
  //       console.error('LOGIN failed', error);
  //       if (error.error.httpStatus === "UNAUTHORIZED" && error.error.message === "Invalid login credentials") {
  //         this.hasInvalidCredentials = true;
  //       }
  //     });
  //   }
  // }

  onCustomSignIn() {
    if (this.signInForm.valid) {
      this.authService.customLogin(this.signInForm.value).subscribe(response => {
        console.log('LOGIN successful', response); // Handle success response
        if (response.status === 'PERMANENT_REDIRECT' && response.redirectUrl) {
          const redirectUrl = decodeURIComponent(response.redirectUrl);
          console.log('LOGIN successful redirectUrl decodeURIComponent ', redirectUrl); 
  
          // Extract the base path and query parameters
          
          // this.router.navigate([redirectUrl]);
          window.location.href = redirectUrl;

        } else {
          console.log('Response:', response);
        }
      }, error => {
        console.error('LOGIN failed', error);
        if (error.error.httpStatus === "UNAUTHORIZED" && error.error.message === "Invalid login credentials") {
          this.hasInvalidCredentials = true;
        }
      });
    }
  }
  
  

  onCustomSignUp(): void {
    if (this.signUpForm.valid) {
      this.authService.preRegister(this.signUpForm.value).subscribe(response => {
        console.log('Pre Registration successful', response); // Handle success response
        this.router.navigate([AppRoutePathConstants.PATH_EMAIL_VERIFICATION_PROMPT]);
      }, error => { console.error('Pre Registration failed', error); });
    }
  }

  onForgotPassword() { 
    this.router.navigate([AppRoutePathConstants.PATH_PASSWORD_RESET]);
  }


  flip() {
    this.flipped = !this.flipped;
  }
  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }
}
