import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../commons/app-constants';
import { AppRoutePathConstants } from '../commons/app-route-path-constants';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

   
  apiUrl = AppRoutePathConstants.PATH_SERVER_URL

  constructor(private http: HttpClient) {}

  sendResetLink(email: string): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}api/v1/password/forgot`, { email });
  }

  resetPassword(token: string, newPassword: string): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}api/v1/password/reset`, { token, newPassword });
  }
}
