import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  secId: any;
  
  constructor(private paymentService: PaymentService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.paramMap.subscribe(params => {
      this.secId = params.get('sec_id') || '';  // Get 'id' parameter from the URL
      console.log('User ID from URL:', this.secId);

      this.paymentService.processRoleAssignmentForPayment(this.secId).subscribe(
        (success: any) => {
          alert(`Successfully processedRoleAssignmentForPayment `)
        },
        (error: any) => {
          alert(`Failed to processRoleAssignmentForPayment `)
        },
        () => { },
      )
    });
  }

  ngOnInit(): void {

  }

}
