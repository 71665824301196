<div class="flex lg:justify-content-center flex-wrap m-0 p-0 md:p-1">


  <p-fieldset legend="Company Details" class="col-12 md:col-9 p-0 md:p-1 m-0 p-0 md:p-1">
    <form [formGroup]="companyForm" (ngSubmit)="onSubmit('next')" class="p-fluid">
      <input type="hidden" formControlName="id" />
      <input type="hidden" formControlName="companyId" />

      <!-- Individual Checkbox -->

      <div class="formgrid grid col-9 md:col-12 m-0 w-full p-0 md:p-1 m-0 p-0 md:p-1">



        <div class="field col-12 md:col-12 pt-2">
          <p-checkbox inputId="isIndividual" formControlName="isIndividual" binary="true"
            label="Check here if you are registering as an individual without a company."
            (onChange)="toggleIsIndividual($event)"></p-checkbox>
          <!-- <label for="isIndividual">I'm an Individual</label> -->
        </div>



        <div class="field col-12 md:col-6 pt-2">
          <label for="companyName" class="block mb-2">Company Name <i class="ml-1 text-red-500">*</i></label>
          <!-- Ensure label takes full width -->
          <p-iconField>
            <p-inputIcon>
              <i class="pi pi-id-card"></i>
            </p-inputIcon>
            <input pInputText formControlName="companyName" id="companyName" type="text" class="text-base text-color surface-overlay  border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary w-full" />
          </p-iconField>
          <div *ngIf="isControlInvalid('companyForm','companyName')" class="text-red-500">
            {{ getErrorMessage('companyName') }}
          </div>
        </div>
        <div class="field col-12 md:col-6">
        </div>

        <div class="field col-12 md:col-6 pt-2">
          <label for="taxId" class="block mb-2">Tax ID <i class="ml-1 text-red-500">*</i></label>
          <!-- Ensure label takes full width -->
          <p-iconField>
            <p-inputIcon>
              <i class="pi pi-credit-card"></i>
            </p-inputIcon>
            <input pInputText formControlName="taxId" id="taxId" type="text" class="text-base text-color surface-overlay  border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary w-full" />
          </p-iconField>
          <div *ngIf="!companyForm.controls['taxId'].value && companyForm.controls['taxId'].touched"
            class="text-orange-500">
            We recommend entering your Tax ID for tax purposes.
          </div>
        </div>
        <div class="field col-12 md:col-6 pt-2">

          <label for="legalEntityType">Legal Entity Type</label>
          <p-dropdown formControlName="legalEntityType" [options]="legalEntityOptions"
            placeholder="Select Legal Entity of Company"
            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full">
          </p-dropdown>
          <div *ngIf="isControlInvalid('companyForm','legalEntityType')">
            {{ getErrorMessage('legalEntityType') }}
          </div>

        </div>
        <!--  <app-location-selection (resetFormRequest)="onReset()" [isFromSidebar]="isSubmittedFromSidebar"
          formControlName="location" (locationFormStatus)="onLocationFormStatus($event)"
          [locationFormValidators]="locationFormValidators" [existingLocationData]="existingLocationData"
          [isAddress2Visible]="true" [isAddress2Optional]="true" [isStateOptional]="true" [isCityOptional]="true"
          [isZipOptional]="true"></app-location-selection> -->

        <app-location-selection #locationSelection (resetFormRequest)="onReset()" formControlName="location"
          (locationFormStatus)="onLocationFormStatus($event)" [locationFormValidators]="locationFormValidators"
          [existingLocationData]="existingLocationData" [isAddress2Visible]="true" [isAddress2Optional]="true"
          [isStateOptional]="true" [isCityOptional]="true" [isZipOptional]="true"></app-location-selection>

        <!-- <div class="field col-12">
          <label for="address1">Address 1 <i class="ml-1 text-red-500">*</i></label>
          <input id="address1" pInputText type="text" formControlName="address1"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          <div *ngIf="isControlInvalid('companyForm','address1')" class="text-red-500">
            {{ getErrorMessage('address1') }}
          </div>
        </div>
        <div class="field col-12">
          <label for="address2">Address 2</label>
          <input id="address2" pInputText type="text" formControlName="address2"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          <div *ngIf="isControlInvalid('companyForm','address2')" class="text-red-500">
            {{ getErrorMessage('address2') }}
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <label for="country">Country <i class="ml-1 text-red-500">*</i></label>
          <p-dropdown formControlName="country" [options]="dummy" optionLabel="name" placeholder="Select a Country"
            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full" />
          <div *ngIf="isControlInvalid('companyForm','country')" class="text-red-500">
            {{ getErrorMessage('country') }}
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <label for="state">State</label>
          <p-dropdown formControlName="state" [options]="dummy" optionLabel="name" placeholder="Select a State"
            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full" />
          <div *ngIf="isControlInvalid('companyForm','state')" class="text-red-500">
            {{ getErrorMessage('state') }}
          </div>
        </div>
        <div class="field col-12 md:col-9">
          <label for="city">City</label>
          <p-dropdown formControlName="city" [options]="dummy" optionLabel="name" placeholder="Select a City"
            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full" />
          <div *ngIf="isControlInvalid('companyForm','city')" class="text-red-500">
            {{ getErrorMessage('city') }}
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <label for="zip">Zip <i class="ml-1 text-red-500">*</i></label>
          <input id="zip" type="number" formControlName="zip" pInputText
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          <div *ngIf="isControlInvalid('companyForm','zip')" class="text-red-500">
            {{ getErrorMessage('zip') }}
          </div>
        </div> -->

        <div class="field col-12 md:col-6">
          <label for="industryType">Industry Type</label>
          <p-multiSelect [options]="industryTypeOptions" formControlName="industryType"
            styleClass="text-base text-color surface-overlay text-color border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            placeholder="Select Industry" />
          <div *ngIf="isControlInvalid('companyForm','industryType')" class="text-red-500">
            {{ getErrorMessage('industryType') }}
          </div>
        </div>

        <div class="field col-12 md:col-6">
          <label for="companySize">Company Size</label>
          <p-dropdown formControlName="companySize" [options]="companySizeOptions" placeholder="Select Company Size"
            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full">
          </p-dropdown>

          <div *ngIf="isControlInvalid('companyForm','companySize')" class="text-red-500">
            {{ getErrorMessage('companySize') }}
          </div>
        </div>

        <div class="field col-12 md:col-6">
          <label for="roleInCompany">Role in Company</label>
          <p-dropdown formControlName="roleInCompany" [options]="roleOptions" placeholder="Select Role in Company"
            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full">
          </p-dropdown>

          <div *ngIf="isControlInvalid('companyForm','roleInCompany')" class="text-red-500">
            {{ getErrorMessage('roleInCompany') }}
          </div>
        </div>
      </div>
    </form>
    
    <ng-container *ngIf="!isEditForm; else insertForm">
      <div class="flex pt-4 justify-content-between">
        <p-button label="Previous" icon="pi pi-arrow-left" iconPos="left" (onClick)="onSubmit('previous')" />
        <p-button label="Next" icon="pi pi-arrow-right" iconPos="right" (onClick)="onSubmit('next')" />
      </div>
    </ng-container>

    <ng-template #insertForm>
      <div class="flex pt-4 justify-content-between">
        <!-- <p-button label="Previous" icon="pi pi-arrow-left" iconPos="left" (onClick)="onSubmit('previous')" /> -->
        <p-button label="Update" icon="pi pi-save" iconPos="right" (onClick)="onUpdate()" />
      </div>
    </ng-template>


  </p-fieldset>


</div>