import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {



  constructor(private http: HttpClient) {}

  getCurrentLocation(): Promise<{ lat: number; lng: number, response?:any }> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (position) {
              const lat = position.coords.latitude;
              const lng = position.coords.longitude;
              this.getAddress(lat,lng).subscribe(
                (response)=>{
                  resolve({ lat, lng, response });
                },
                (error)=>{
                  resolve({ lat, lng });
                },
                ( )=>{},

              )

            }
          },
          (error) => reject(error)
        );
      } else {
        reject('Geolocation is not supported by this browser.');
      }
    });
  }

  getAddress(lat: number, lon: number): Observable<any> {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`;
    return this.http.get(url);
  }
}
