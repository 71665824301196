<!-- verification-status.component.html -->
<div class="p-grid p-justify-center p-align-center p-mt-5">
    <div class="p-card p-shadow-5 w-fluid" *ngIf="verificationStatus">
        <div class="p-card-header p-text-center">
            <i class="pi" [ngClass]="{
            'pi-check-circle p-text-success': verificationStatus === 'success',
            'pi-exclamation-circle p-text-danger': verificationStatus === 'error'
          }" style="font-size: 3rem;"></i>
            <h2>{{ verificationStatus === 'success' ? 'Verification Successful' : 'Verification Failed' }}</h2>
        </div>
        <div class="p-card-body p-text-center">
            <p *ngIf="verificationStatus === 'success'">
                Your email has been successfully verified. You can now proceed to log in and access your account.
            </p>
            <p *ngIf="verificationStatus === 'error'">
                We couldn't verify your email. The link may have expired or already been used. Please try requesting a
                new verification email.
            </p>
        </div>
        <div class="p-card-footer p-text-center">
            <button *ngIf="verificationStatus === 'error'" pButton label="Resend Verification Email"
                class="p-button-rounded p-button-outlined p-mr-2" (click)="resendVerificationEmail()"></button>
            <button pButton label="Login" class="p-button-rounded p-button-success" routerLink="/login"></button>
        </div>
    </div>
    <div *ngIf="loading" class="p-text-center p-mt-5">
        <i class="pi pi-spin pi-spinner p-text-primary" style="font-size: 3rem;"></i>
        <p>Verifying your email...</p>
    </div>
</div>