import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

declare var Razorpay: any; // Razorpay is available globally from the script


@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    rzp!: any;
    constructor(private http: HttpClient, private router : Router) {
        this.rzp = new Razorpay({
            key: environment.razorpayKey,
            // logo, displayed in the popup
          //  image: 'https://i.imgur.com/n5tjHFD.jpg',
        });
        this.rzp.once('ready', function (response) {
          //  alert(JSON.stringify(response.methods));
        })
        // Attach event listeners
        this.rzp.on('payment.success', (resp) => {
            alert(`Payment Success:\nPayment ID: ${resp.razorpay_payment_id}`);
            alert(`Order ID: ${resp.razorpay_order_id}`);
            alert(`Signature: ${resp.razorpay_signature}`);
        });

        this.rzp.on('payment.error', (resp) => {
            alert('Payment Failed');
            alert(`Error: ${resp.error.description}`);
        });

        this.rzp.on('payment.dismiss', () => {
            alert('Payment window dismissed by user.');
        });

        this.rzp.on('payment.failed', (resp) => {
            alert('Payment Failed');
            alert(`Code: ${resp.error.code}`);
            alert(`Description: ${resp.error.description}`);
            alert(`Source: ${resp.error.source}`);
            alert(`Step: ${resp.error.step}`);
            alert(`Reason: ${resp.error.reason}`);
            alert(`Order ID: ${resp.error.metadata.order_id}`);
            alert(`Payment ID: ${resp.error.metadata.payment_id}`);
        });
    }

    onPayNow(subscriptionPaymentBillingDetails: any) {
        console.log('Pay Now initiated');
        // Call backend to create Razorpay order
        this.http.post(`${environment.apiUrl}api/v1/payment/createOrder`, subscriptionPaymentBillingDetails)
            .subscribe({
                next: (order: any) => {
                    console.log('Order created:', order);
                    //this.initiateRazorpayPayment(order);
                    this.rzp = new Razorpay(order);
                    this.rzp.open();


                },
                error: (error) => {
                    console.error('Error creating Razorpay order:', error);
                }
            });
    }

    initiateRazorpayPayment(order: any) {
        console.log(`environment.razorpayKey  ${environment.razorpayKey})}
    order ${JSON.stringify(order)}`);
        // var options = {
        //     "key": order.,
        //     "amount": order.amount,
        //     "currency": order.currency,
        //     "name": subscriptionPaymentBillingDetails.companyName,
        //     "description": "Test Transaction",
        //     "image": "https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp",
        //     "order_id": order.id,

        //     "prefill": {
        //         "name": subscriptionPaymentBillingDetails.companyName,
        //         "email": "gaurav.kumar@example.com",
        //         "contact": "9000090000"
        //     },
        //     "notes": {
        //         "address": "Razorpay Corporate Office"
        //     },
        //     "theme": {
        //         "color": "#3399cc"
        //     }
        // };


        /* var data = {
           callback_url: 'https://www.examplecallbackurl.com/',
           amount:  order.amount, // in currency subunits. Here 1000 = 1000 paise, which equals to ₹10
           currency: "INR",// Default is INR. We support more than 90 currencies.
           email: 'gaurav.kumar@example.com',
           contact: '9123456780',
           description: 'Subscription Payment',
           notes: {
             address: 'Ground Floor, SJR Cyber, Laskar Hosur Road, Bengaluru',
           },
           prefill: {
             email: subscriptionPaymentBillingDetails?.user?.email ?? '',
             contact: subscriptionPaymentBillingDetails?.user?.mobile ?? ''
           },
           order_id: order.id,// Replace with Order ID generated in Step 4
          // method: 'netbanking',
         
           // method specific fields
           // bank: 'HDFC'
           theme: {
             color: '#3399cc'
           }
         };
         
         
           this.rzp.createPayment(data); */

        /* const options = {
           key: environment.razorpayKey, // Razorpay key
           amount: order.amount,         // Amount in paise
           currency: order.currency,
           name: 'Company Name',
           description: 'Subscription Payment',
           order_id: order.id,
           handler: (response: any) => {
             this.verifyPayment(response);
           },
           prefill: {
             email: subscriptionPaymentBillingDetails?.user?.email ?? '',
             contact: subscriptionPaymentBillingDetails?.user?.mobile ?? ''
           },
           theme: {
             color: '#3399cc'
           }
         };/*/

        /* const razorpay = new Razorpay(options);
         razorpay.on('payment.failed', (response: any) => {
           console.error('Payment failed:', response.error);
         });
         razorpay.open();*/


        // To log available methods (based on `ready` event)



        //  this.rzp.createPayment(data);

        this.rzp.on('payment.success', function (resp) {
            alert(resp.razorpay_payment_id),
                alert(resp.razorpay_order_id),
                alert(resp.razorpay_signature)
        }); // will pass payment ID, order ID, and Razorpay signature to success handler.

        this.rzp.on('payment.error', function (resp) { alert(resp.error.description) }); // will pass error object to error handler



        // rzp.open();
    }

    verifyPayment(paymentResponse: any) {
        alert(`verifyPayment`)
        console.log('Verifying payment:', paymentResponse);
        this.http.post(`${environment.apiUrl}api/v1/payment/verifyPayment`, paymentResponse)
            .subscribe({
                next: (verificationResponse) => {
                    console.log('Payment verification success:', verificationResponse);
                      alert(`verifyPayment Response ${JSON.stringify(verificationResponse)}`)

                    // Redirect to success page or show success message
                    this.router.navigate(['/account-setup/payment-confirmation'], { replaceUrl: true });
                },
                error: (error) => {
                    console.error('Payment verification failed:', error);
                }
            });
    }

    processRoleAssignmentForPayment(secId: string): Observable<any> {
      //const headers = new HttpHeaders().set('Content-Type', 'application/json');
  
      return this.http.post<any>(`${environment.apiUrl}api/v1/payment/transaction-handler`, { secId });
    }
}
