<div class="center-screen">

	<div class="flip-container flip-container-right" [class.flipped]="flipped">
		<div class="flipper">
			<p-card class="back">
				<div class="w-full lg:w-*">
					<div class="text-center mb-5">
						<img src="https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp" alt="Lumberlinq"
							class="mb-3" style="height: 4rem;">
						<div class="text-900 text-xl font-medium mb-3">Already have an account?</div>
						<a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="flip()">Sign in
							now!</a>
						<hr />
					</div>
					<div class="w-full text-center mb-4">
						<span class="text-lg text-bold">Sign Up</span>
					</div>

					<form [formGroup]="signUpForm" (ngSubmit)="onCustomSignUp()">
						<div class="formgrid grid w-fluid">

							<!-- Username Field -->
							<div class="field col-12 md:col-12">
								<label for="name">Name</label>
								<input type="text" pInputText formControlName="name"
									class="text-base text-color w-full" />
								<small class="text-red-600"
									*ngIf="signUpForm.get('name')?.hasError('required') && signUpForm.get('name')?.touched">
									Name is required.
								</small>

							</div>

							<!-- Username Field -->
							<div class="field col-12 md:col-12">
								<label for="email">Email</label>
								<input type="email" pInputText formControlName="email"
									class="text-base text-color w-full" />
								<small class="text-red-600"
									*ngIf="signUpForm.get('email')?.hasError('required') && signUpForm.get('email')?.touched">
									Username is required.
								</small>
								<small class="text-red-600"
									*ngIf="signUpForm.get('email')?.hasError('email') && signUpForm.get('email')?.touched">
									Please enter a valid email address.
								</small>
								<small class="text-red-600" *ngIf="emailExists">
									Email already exists.
								</small>

							</div>

							<!-- Password Field -->
							<div class="field col-12 md:col-12 w-full">
								<label for="password">Password</label>
								<!-- <input type="password" pInputText formControlName="password"
									class="text-base text-color w-full" /> -->
									<p-password  id="password"  type="password" class="w-full"  placeholder="Password"
               styleClass="p-password p-component p-inputwrapper p-input-icon-right" [style]="{'width':'100%'}"
               [inputStyle]="{'width':'100%'}" [toggleMask]="true"   formControlName="password"
               promptLabel="Choose a password"
               weakLabel="Too simple" 
               mediumLabel="Average complexity" 
               strongLabel="Complex password" ></p-password>

								<small class="text-red-600"
									*ngIf="signUpForm.get('password')?.hasError('required') && signUpForm.get('password')?.touched">
									Password is required.
								</small>

							</div>

							<!-- Sign In Button -->
							<div class="field col-12">
								<button pButton type="submit" label="Sign Up" class="w-full"></button>
							</div>


						</div>
					</form>

					<div class="flex align-items-center justify-center my-3">
						<div class="flex-grow-1 border-top border-1 surface-border"></div>
						<span class="px-3 text-secondary">or</span>
						<div class="flex-grow-1 border-top border-1 surface-border"></div>
					</div>
					<div class="w-full flex justify-content-center align-items-center gap-3">
						<a [href]="facebookUrl" rel="noopener noreferrer">
							<button pButton type="button" class="facebook-btn" icon="pi pi-facebook"
								[rounded]="true"></button>
						</a>

						<a [href]="googleUrl" rel="noopener noreferrer">
							<button pButton type="button" class="google-btn" icon="pi pi-google"
								[rounded]="true"></button>
						</a>

						<a [href]="microsoftUrl" rel="noopener noreferrer">
							<button pButton type="button" class="microsoft-btn" icon="pi pi-microsoft"
								[rounded]="true"></button>
						</a>

						<a [href]="facebookUrl" rel="noopener noreferrer">
							<button pButton type="button" class="linkedin-btn" icon="pi pi-linkedin"
								[rounded]="true"></button>
						</a>
					</div>


				</div>
			</p-card>
			<p-card class="front">
				<div class="w-full lg:w-*">
					<div class="text-center mb-5">
						<img src="https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp" alt="Lumberlinq"
							class="mb-3" style="height: 4rem;">
						<div class="text-900 text-xl font-medium mb-3">Don't have an account?</div>
						<a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="flip()">Create
							today!</a>
						<hr />
					</div>
					<div class="w-full text-center mb-4">
						<span class="text-lg text-bold">Sign In</span>
					</div>

					<form [formGroup]="signInForm" (ngSubmit)="onCustomSignIn()">
						<input type="hidden" formControlName="deviceId" />
						<p-messages 
						[(value)]="messages" 
						[enableService]="false" 
						*ngIf="hasInvalidCredentials" />

						<div class="formgrid grid w-fluid">

							<!-- Username Field -->
							<div class="field col-12 md:col-12">
								<label for="email">Email</label>
								<input type="email" pInputText formControlName="email"
									class="text-base text-color w-full" />
								<small class="text-red-600"
									*ngIf="signInForm.get('email')?.hasError('required') && signInForm.get('email')?.touched">
									Username is required.
								</small>
								<small class="text-red-600"
									*ngIf="signInForm.get('email')?.hasError('email') && signInForm.get('email')?.touched">
									Please enter a valid email address.
								</small>
								
							</div>

							<!-- Password Field -->
							<div class="field col-12 md:col-12 w-full">
								<label for="password">Password</label>
								<!-- <input type="password" pInputText formControlName="password"
									class="text-base text-color w-full" /> -->
									<p-password  id="password"  type="password" class="w-full"  placeholder="Password"
									styleClass="p-password p-component p-inputwrapper p-input-icon-right" [style]="{'width':'100%'}"
									[inputStyle]="{'width':'100%'}" [toggleMask]="true"   formControlName="password"
									promptLabel="Choose a password"
									weakLabel="Too simple" 
									mediumLabel="Average complexity" 
									strongLabel="Complex password" ></p-password>

								<small class="text-red-600"
									*ngIf="signInForm.get('password')?.hasError('required') && signInForm.get('password')?.touched">
									Password is required.
								</small>
								<div class="flex justify-content-end mt-0">
									<p-button label="Forgot Password?" link="true" class="p-button-text text-sm"
										(click)="onForgotPassword()"></p-button>
								</div>
							</div>

							<!-- Sign In Button -->
							<div class="field col-12">
								<button pButton type="submit" label="Sign In" class="w-full"></button>
							</div>


						</div>
					</form>

					<div class="flex align-items-center justify-center my-3">
						<div class="flex-grow-1 border-top border-1 surface-border"></div>
						<span class="px-3 text-secondary">or</span>
						<div class="flex-grow-1 border-top border-1 surface-border"></div>
					</div>
					<div class="w-full flex justify-content-center align-items-center gap-3">
						<a [href]="facebookUrl" rel="noopener noreferrer">
							<button pButton type="button" class="facebook-btn" icon="pi pi-facebook"
								[rounded]="true"></button>
						</a>

						<a [href]="googleUrl" rel="noopener noreferrer">
							<button pButton type="button" class="google-btn" icon="pi pi-google"
								[rounded]="true"></button>
						</a>

						<a [href]="microsoftUrl" rel="noopener noreferrer">
							<button pButton type="button" class="microsoft-btn" icon="pi pi-microsoft"
								[rounded]="true"></button>
						</a>

						<a [href]="facebookUrl" rel="noopener noreferrer">
							<button pButton type="button" class="linkedin-btn" icon="pi pi-linkedin"
								[rounded]="true"></button>
						</a>
					</div>


				</div>
			</p-card>
		</div>
	</div>
</div>

<div class="alert alert-success" *ngIf="isLoggedIn">
	You're already loggedin as
	{{currentUser?.firstName ?? ''}} <br>Logged in as {{ currentUser?.email ?? '' }}.
</div>

<p-dialog header="Error Notification" [draggable]="false" [resizable]="false" [(visible)]="visible"
	[style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }">
	<p>
		{{ error }}
	</p>
	<ng-template pTemplate="footer">
		<p-button (click)="visible = false" label="Ok" styleClass="p-button-text"></p-button>
	</ng-template>
</p-dialog>
<!-- <div
		class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
		<div class="flex flex-column align-items-center justify-content-center">
			<div
				style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
				<div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px"
					*ngIf="!register; else signup">
					<div class="text-center mb-5">
						<img src="https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp" alt="Lumberlinq"
							class="mb-3" style="height: 4rem;">
						<div class="text-900 text-3xl font-medium mb-3">Welcome</div>
						<span class="text-600 font-medium">Sign in to continue</span>
					</div>

					<div>
						<a [href]="facebookUrl" rel="noopener noreferrer">
							<button pButton type="button" label="Sign in with Facebook" class="facebook-btn w-full mb-3"
								icon="pi pi-facebook"></button>
						</a>

						<a [href]="googleUrl" rel="noopener noreferrer">
							<button pButton type="button" label="Sign in with Google" class="google-btn w-full mb-3"
								icon="pi pi-google"></button>
						</a>

						<a [href]="microsoftUrl" rel="noopener noreferrer">
							<button pButton type="button" label="Sign in with Microsoft"
								class="microsoft-btn w-full mb-3" icon="pi pi-microsoft"></button>
						</a>

						<a [href]="facebookUrl" rel="noopener noreferrer">
							<button pButton type="button" label="Sign in with LinkedIn" class="linkedin-btn w-full mb-3"
								icon="pi pi-linkedin"></button>
						</a>

					</div>

				</div>

				<ng-template #signup>
					<div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
						<div class="text-center mb-5">
							<img src="https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp"
								alt="Lumberlinq" class="mb-3" style="height: 4rem;">
							<div class="text-900 text-3xl font-medium mb-3">Welcome</div>
							<span class="text-600 font-medium">Sign up to continue</span>
						</div>

						<div>
							<button pButton type="button" label="Sign up with Google" class="google-btn w-full mb-3"
								icon="pi pi-google"></button>

							<button pButton type="button" label="Sign up with Google" class="google-btn w-full mb-3"
								icon="pi pi-facebook"></button>
							<a [href]="facebookUrl">Login with
								Facebook</a>
							<p-button label="Go to Page" styleClass="p-button-link" [routerLink]="facebookUrl">
							</p-button>


							<button pButton type="button" label="Sign in with Microsoft"
								class="microsoft-btn w-full mb-3" icon="pi pi-microsoft"></button>

							<button pButton type="button" label="Sign up with LinkedIn" class="linkedin-btn w-full mb-3"
								icon="pi pi-linkedin"></button>

						</div>

					</div>
				</ng-template>


			</div>
		</div>
	</div>
</form>

<p-dialog header="Error Notification" [draggable]="false" [resizable]="false" [(visible)]="visible"
	[style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }">
	<p>
		{{ error }}
	</p>
	<ng-template pTemplate="footer">
		<p-button (click)="visible = false" label="Ok" styleClass="p-button-text"></p-button>
	</ng-template>
</p-dialog> -->