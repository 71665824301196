import { Component } from '@angular/core';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-email-verification-prompt',
  standalone: true,
  imports: [SHARED_MODULES],
  templateUrl: './email-verification-prompt.component.html',
  styleUrl: './email-verification-prompt.component.css'
})
export class EmailVerificationPromptComponent {

  refreshPage() {
    window.location.reload();
  }
}
