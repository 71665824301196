import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FloatLabelModule } from 'primeng/floatlabel';
import { IconFieldModule } from 'primeng/iconfield';
import { InplaceModule } from 'primeng/inplace';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputIconModule } from 'primeng/inputicon';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SpeedDialModule } from 'primeng/speeddial';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { SelectButtonChangeEvent, SelectButtonModule } from 'primeng/selectbutton';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Currency } from 'src/app/interfaces/tally-sheet/currency';
import { SubscriptionPackage } from 'src/app/interfaces/tally-sheet/subscription-package';
import { Tenure } from 'src/app/interfaces/tally-sheet/tenure';
import { AccountSetupStepperService } from 'src/app/services/tally-sheet/account-setup-stepper.service';
import { LocationService } from 'src/app/services/tally-sheet/location.service';
import { SecureDataPassService } from 'src/app/services/tally-sheet/secure-data-pass.service';
import { SubscriptionService } from 'src/app/modules/extras/services/subscription.service';


export class SelectedSubscriptionPackage {
  currency: string;
  subscriptionPackageId: number;
  tenure: string;
}

@Component({
  selector: 'app-subscription-package',
  standalone: true,
  imports: [FormsModule, CommonModule, ReactiveFormsModule, ButtonModule, BlockUIModule, PanelModule, ProgressBarModule, InplaceModule,
    InputGroupModule, InputGroupAddonModule, ToastModule, DialogModule, ProgressSpinnerModule, ConfirmDialogModule, CardModule,
    ToggleButtonModule, InputNumberModule, TooltipModule, MultiSelectModule, ToolbarModule, AvatarModule,
    TableModule, SpeedDialModule, AccordionModule, BadgeModule, ConfirmPopupModule, InputSwitchModule,
    ClipboardModule, DividerModule, FieldsetModule, PasswordModule, IconFieldModule, InputIconModule, InputTextModule, FloatLabelModule,
    CalendarModule, RadioButtonModule, DropdownModule, SelectButtonModule
  ],
  templateUrl: './subscription-package.component.html',
  styleUrl: './subscription-package.component.scss'
})
export class SubscriptionPackageComponent implements OnInit, AfterViewInit {
  isSubmitted = false;

  subscriptionForm!: FormGroup;
  currencies: any[] | undefined;
  subscriptionPackages: SubscriptionPackage[] = [];
  subscriptionStartDate!: any;
  subscriptionEndDate!: any;
  taxAmount!: any;
  totalAmount!: any;
  selectedSubscriptionPackage: SelectedSubscriptionPackage;
  tenureOptions: any[] = [
    { label: 'Monthly', value: Tenure.MONTHLY },
    { label: 'Yearly', value: Tenure.YEARLY }
  ];

  selectedPackageIndex: number | null = null;
  selectedPackage!: any | null;

  constructor(private fb: FormBuilder, private stepperService: AccountSetupStepperService,
    private locationService: LocationService, private router: Router,
    // private secureDataService: SecureDataPassService,
    private subscriptionService: SubscriptionService,
    private route: ActivatedRoute

  ) {
    this.selectedSubscriptionPackage = new SelectedSubscriptionPackage();
    this.initSubscriptionPackages();

  }


  ngOnInit() {
    this.checkPathForEditForm();
    this.initCurrencyList();


    this.subscriptionForm = this.fb.group({
      currency: new FormControl<{ name: Currency.USD, code: 'USD' }>({ name: Currency.USD, code: 'USD' }, Validators.required),
      tenure: new FormControl('YEARLY', Validators.required),
      subscriptionPackage: new FormControl(null, Validators.required),

      // fullName: ['', Validators.required],
      // email: ['', [Validators.required, Validators.email]],
      // password: ['', [Validators.required, Validators.minLength(6)]],
      // confirmPassword: ['', Validators.required]
    });
    //   this.getLocation();

  }

  ngAfterViewInit(): void {

    this.stepperService.setActiveStep(2);  // Change the active step in account-setup

  }

  initCurrencyList() {
    this.currencies = [
      { name: Currency.USD, code: 'US' },
      { name: Currency.INR, code: 'IN' }
    ];
  }

  initSubscriptionPackages() {
    this.route.data.subscribe((data) => {
      this.subscriptionPackages = data['subscriptionPackage'];
      console.log(`response of packages ${JSON.stringify(this.subscriptionPackages)}`);
      this.populateForm();

    });
    /* this.subscriptionService.getSubscriptionPackages().subscribe(
      (response: any) => {
        console.log(`response of packages ${response}`);
        this.subscriptionPackages = response;
        this.populateForm();

      },
      (error: any) => { },
      () => { },


    ) */
    /* this.subscriptionPackages = [
      {
        id: 1001,
        sequence: 1,
        name: 'Trial',
        subheader: '14-Day Free Trial',
        USD_MONTHLY: { offerPrice: 0, price: 0, savingsHeader: null }, // Free for 14 days
        INR_MONTHLY: { offerPrice: 0, price: 0, savingsHeader: null }, // Free for 14 days

        USD_YEARLY: null, // No yearly plan for trial
        INR_YEARLY: null, // No yearly plan for trial

        features: [
          { feature: 'Basic Support', available: true },
          { feature: 'Email Notifications', available: true },
          { feature: 'Advanced Analytics', available: false },
          { feature: 'Priority Support', available: false },
          { feature: 'Custom Reports', available: false }
        ],
        duration: '14 Days', // Add a property to specify trial duration
        isActive: false,
        isTrial: true
      },
      {
        id: 1002,
        sequence: 2,
        name: 'Silver',
        subheader: 'Great Value for Individuals',

        USD_MONTHLY: { offerPrice: 20, price: 25, savingsHeader: null },
        INR_MONTHLY: { offerPrice: 1499, price: 1999, savingsHeader: null },

        USD_YEARLY: { offerPrice: 190, price: 300, savingsHeader: "(20% Saving!)" },
        INR_YEARLY: { offerPrice: 14500, price: 24000, savingsHeader: "(20% Saving!)" },

        features: [
          { feature: 'Basic Support', available: true },
          { feature: 'Email Notifications', available: true },
          { feature: 'Advanced Analytics', available: false },
          { feature: 'Priority Support', available: false },
          { feature: 'Custom Reports', available: false }
        ],
        isActive: true,
        isTrial: false

      },
      {
        id: 1003,
        sequence: 3,
        name: 'Gold',
        subheader: 'Best for Businesses',
        USD_MONTHLY: { offerPrice: 25, price: 30, savingsHeader: null },
        INR_MONTHLY: { offerPrice: 1999, price: 2499, savingsHeader: null },

        USD_YEARLY: { offerPrice: 300, price: 360, savingsHeader: "(20% Saving!)" },
        INR_YEARLY: { offerPrice: 24000, price: 30000, savingsHeader: "(20% Saving!)" },
        features: [
          { feature: 'Basic Support', available: true },
          { feature: 'Email Notifications', available: true },
          { feature: 'Advanced Analytics', available: true },
          { feature: 'Priority Support', available: true },
          { feature: 'Custom Reports', available: false }
        ],
        isActive: true,
        isTrial: false

      }
    ]; */
  }

  onCurrencyChange(event: DropdownChangeEvent) {
    console.log(event.value);
    const currency = event.value.code;
    const country = event.value.name;
    console.log(`Selected Currency `, currency, ` Country `, country)
  }

  onTenureChange(event: SelectButtonChangeEvent) {

    const tenure = event.value;
    console.log(`Selected Tenure is `, tenure);
  }

  // Method to check which fields failed validation
  checkFailedFields() {
    Object.keys(this.subscriptionForm.controls).forEach(field => {
      const control = this.subscriptionForm.get(field);
      if (control?.invalid) {
        console.log(`${field} failed due to:`, control.errors);
      }
    });
  }

  onSelectPackage(index: number) {
    this.selectedPackageIndex = index;
    this.selectedPackage = this.subscriptionPackages[index];
    this.selectedPackage.currency = this.subscriptionForm?.get('currency')?.value ?? '';
    this.selectedPackage.tenure = this.subscriptionForm?.get('tenure')?.value ?? '';


    //  this.secureDataService.setSecureData(this.selectedPackage);
    this.subscriptionForm.patchValue({ subscriptionPackage: this.selectedPackage });
    console.log(this.selectedPackage)

  }

  getLocation() {
    this.locationService.getCurrentLocation().then((position) => {
      console.log('Address :', JSON.stringify(position));
      const lat = position.lat;

      const lng = position.lng;
      const response = position.response;
      console.log('Latitude:', lat);
      console.log('Longitude:', lng);
      console.log('response:', response);
      if (response) {
        const country = response.address.country;
        const country_code = response.address.country_code;
        const postcode = response.address.postcode;
        const state = response.address.state;
        const state_district = response.address.state_district;
        const county = response.address.county;
        const suburb = response.address.suburb;
        console.log('response:',
          country,
          country_code,
          postcode,
          state,
          state_district,
          county,
          suburb,
        );
        if ("in" === country_code) {
          this.subscriptionForm.patchValue
            ({ currency: { name: Currency.INR, code: 'IN' } })
        }
        else {
          this.subscriptionForm.patchValue
            ({ currency: { name: Currency.USD, code: 'US' } });
        }
      }

    }).catch((error) => {
      this.subscriptionForm.patchValue
        ({ currency: { name: Currency.USD, code: 'US' } })

    });
  }

  fieldNameMap: { [key: string]: string } = {
    tenure: 'Tenure',
    currency: 'Currency',
    subscriptionPackage: 'Subscription Package',
    mobile: 'Mobile number',
    firstName: 'First Name',
    lastName: 'Last Name',
    gender: 'Gender',
    dateOfBirth: 'Date of Birth',
    address1: 'Address 1',
    address2: 'Address 2',
    country: 'Country',
    state: 'State',
    city: 'City',
    zip: 'Zip',

  };

  isControlInvalid(formName: 'subscriptionForm', controlName: string): boolean {
    const control = (this[formName] as FormGroup)?.get(controlName);
    return control?.invalid && control?.touched || false;
  }

  // Error message generator
  getErrorMessage(formControlName: string): string {
    const formControl = this.subscriptionForm.get(formControlName);
    const formFieldName = this.fieldNameMap[formControlName] || formControlName;

    if (formControl!.hasError('required')) {
      return `${formFieldName} is required`;
    }
    if (formControl!.hasError('email')) {
      return `${formFieldName} is not a valid email`;
    }
    if (formControl!.hasError('passwordInvalid')) {
      return `${formFieldName} must contain at least 1 uppercase, 1 lowercase letter, and 1 number`;
    }
    if (formControl!.hasError('minlength')) {
      return `${formFieldName} must be at least 8 characters long`;
    }
    if (formControl!.hasError('passwordsMismatch')) {
      return `Passwords do not match`;
    }
    return ''; // Return empty string if no errors
  }

  preselectValues(response: any) {
    // Find matching subscription package by ID
    console.log(`IN Preselect Values are ${JSON.stringify(response)}`);
    console.log(`IN Preselect Values are ${response.currency}`);
    console.log(`IN Preselect Values are ${response.tenure}`);
    console.log(`IN Preselect Values are ${response.subscriptionPackage}`);

    const selectedPackage = this.subscriptionPackages.find(pkg => {
      console.log(`Comparing ${pkg.id} with ${response.subscriptionPackageId}`);
      return pkg.id === response.subscriptionPackage.id;
    });

    console.log(`Selected ### Package is ${JSON.stringify(selectedPackage)}`)
    const selectedIndex = this.subscriptionPackages.findIndex(pkg => {
      console.log(`Comparing ${pkg.id} with ${response.subscriptionPackageId}`);
      return pkg.id === response.subscriptionPackage.id;
    });


    console.log(`selectedPackage is ${JSON.stringify(selectedIndex)}`);

    // this.selectedPackage = this.subscriptionPackages[selectedIndex];

    this.onSelectPackage(selectedIndex);
    // Preselect values in the form
    this.subscriptionForm.patchValue({
      currency: this.currencies.find(c => c.name === response.currency),
      tenure: this.tenureOptions.find(t => t.value === response.tenure)?.value,
      subscriptionPackageId: selectedPackage ? selectedPackage.id : null
    });

    // Store the selected package for further use (like display)
    //this.selectedPackage = selectedPackage;
    console.log(this.subscriptionForm.value);
  }

  populateForm() // if saved before
  {
    this.subscriptionService.getCart().subscribe(
      (response: any) => {
        console.log(`Response ${JSON.stringify(response)}`);
        if (response) {
          this.preselectValues(response);
        }
      },
      (error: any) => {
        console.log(`Response ${error}`);
      },
      () => { },
    )
  }

  onSubmit(navigationPoistion?: 'next' | 'previous'| 'next_EditForm') {
    this.isSubmitted = true;
    // Manually trigger change detection

    this.subscriptionForm.touched;
    console.log(this.subscriptionForm.value)
    if (this.subscriptionForm.valid) {
      const formValue = this.subscriptionForm.value;
      // Go to next step
      console.log(formValue);
      this.checkFailedFields(); // Custom method to check failed fields

      console.log(this.subscriptionForm);  // Handle the combined form data here
      const currency = formValue.currency.name;
      const subscriptionPackageId = formValue.subscriptionPackage.id;
      const tenure = formValue.tenure;
      this.selectedSubscriptionPackage.currency = currency;
      this.selectedSubscriptionPackage.subscriptionPackageId = subscriptionPackageId;
      this.selectedSubscriptionPackage.tenure = tenure;

      console.log(`currency ${currency} Subscription Package Id ${subscriptionPackageId} Tenure ${tenure}`);
      console.log(`Selected Subscription Package is ${JSON.stringify(this.selectedSubscriptionPackage)}`);

      this.subscriptionService.saveTempCart(this.selectedSubscriptionPackage).subscribe(
        (response: any) => {
          console.log(`response is ${JSON.stringify(response)}`);
          if (navigationPoistion === 'previous') {
            this.stepperService.setActiveStep(1);
            this.router.navigate(['account-setup/company-details']);
          }
          if (navigationPoistion === 'next') {
            this.stepperService.setActiveStep(3);
            this.router.navigate(['account-setup/payment']);
          }
          if(navigationPoistion ==='next_EditForm'){
            this.router.navigate(['payment']);
          }
        },
        (error: any) => {
          alert(`Error while saving`);
        },
        () => { },
      )

      // Change the active step in account-setup
      /*  if (navigationPoistion === 'previous') {
         this.stepperService.setActiveStep(1);
         this.router.navigate(['account-setup/company-details']);
       }
       if (navigationPoistion === 'next') {
         this.stepperService.setActiveStep(3);
         this.router.navigate(['account-setup/payment']);
       } */

    }
  }

  ///
  isEditForm = false;
  checkPathForEditForm(): void {
 //   alert(`this.router.url ${this.router.url} + ${this.router.url.includes('subscriptions#purchase')}`)
    // if (this.router.url === 'subscriptions#purchase') {
      if (this.router.url.includes('subscriptions#purchase')) {
      console.log('Path /subscriptions#purchase is accessed');
      this.isEditForm = true;
      // Add any logic needed when this path is accessed
    }
  }

  // Helper method for validation messages
  get f() {
    return this.subscriptionForm.controls;
  }

}


