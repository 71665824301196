<!-- <p-toast></p-toast> -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>

<!-- <div class="pb-3">
    <p-breadcrumb [model]="breadCumbItems" [home]="home"></p-breadcrumb>
</div> -->

<p-card [style.overflow-x]="'auto'">
    <p-table [columns]="selectedColumns" [value]="history" [tableStyle]="{ 'min-width': '50rem' }"
        styleClass="p-datatable">
        <!-- <ng-template pTemplate="caption">
            <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{'min-width': '200px'}"
                placeholder="Choose Columns"></p-multiSelect>
        </ng-template> -->
        <ng-template pTemplate="header" let-columns>
            <tr>
                <!-- <th *ngFor="let col of columns" class="text-center">
                    {{col.header}}
                </th> -->
                <th class="text-center">SR</th>
                <th class="text-center" pSortableColumn="createdOn">Date <p-sortIcon field="createdOn" /></th>
                <th class="text-center" pSortableColumn="subscriptionStart">Start Date <p-sortIcon
                        field="subscriptionStart" /></th>
                <th class="text-center" pSortableColumn="subscriptionEnd">End Date <p-sortIcon
                        field="subscriptionEnd" /></th>
                <th class="text-center">Package</th>
                <th class="text-center" pSortableColumn="subscriptionPackageTenure">Duration <p-sortIcon
                        field="subscriptionPackageTenure" /></th>
                <th class="text-center" pSortableColumn="payableAmount">Amount <p-sortIcon field="payableAmount" /></th>
                <th class="text-center">Transaction Id</th>
                <th class="text-center" pSortableColumn="paymentStatus">Payment Status <p-sortIcon
                        field="paymentStatus" /></th>
                <th class="text-center">
                    <i class="pi pi-download" style="font-size: 1rem"></i>
                </th>
            </tr>
        </ng-template>



        <ng-template pTemplate="body" let-eachHistory let-rowIndex="rowIndex">
            <tr>
                <td class="text-center">{{ rowIndex + 1 }}</td>
                <td class="text-center"> {{ eachHistory.createdOn }}</td>
                <td class="text-center"> {{ eachHistory.subscriptionStart
                    }}</td>
                <td class="text-center"> {{ eachHistory.subscriptionEnd }}
                </td>
                <td class="text-center">{{
                    eachHistory.subscriptionPackageName }}</td>
                <td class="text-center">{{
                    eachHistory.subscriptionPackageTenure }}</td>
                <td class="text-center">{{ eachHistory.payableAmount |
                    currency:
                    eachHistory.currency:'symbol':'1.2-2' }}</td>
                <td class="text-center"> {{ eachHistory.paymentId }}</td>
                <td class="text-center">{{ eachHistory.paymentStatus }}</td>

                <td class="text-center">
                    <a [class.disabled]="eachHistory.paymentStatus === 'TRIAL' || eachHistory.paymentStatus === 'PENDING'"
                        href="#" (click)="$event.preventDefault();downloadInvoice(eachHistory.id)">
                        <i class="pi pi-download" style="font-size: 1rem"></i>

                    </a>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>